<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: ['options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData: {
      handler () {
        this.renderChart(this.chartData, this.options)
      },
      deep: true
    },
    options: {
      handler () {
        this.renderChart(this.chartData, this.options)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
