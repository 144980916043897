import inflection from 'inflection'
import _ from 'lodash'
import Vue from 'vue'
import filterOrchestrator from './filterOrchestrator'

export function registerMasterDetailResourceWithStore (moduleName, store, options) {
  if (store.state[moduleName]) throw new Error(`${moduleName} module already registered with store`)

  const eventBus = new Vue()

  store.registerModule(moduleName, {
    namespaced: true,
    state () {
      return {
        resourceName: options.resourceName,
        addResourceText: options.addResourceText,
        routeName: options.routeName,
        auditResourceName: options.auditResourceName,
        icon: options.icon,
        // TODO: implement the commented-out properties
        // crudService: options.crudService,
        // allColumns: options.allColumns,
        denormFields: options.denormFields || {},
        // view :< { list, edit, add }
        // view: null,
        masterItems: [],
        cursor: null,
        originalData: {},
        detailIndex: null,
        // only entire formData object is reactive, not nested properties
        formData: {},
        formInvalid: false,
        readOnly: false,
        supplementalData: {}
      }
    },
    getters: {
      pluralResourceName: state => inflection.pluralize(state.resourceName),
      addingNew: state => !_.get(state.originalData, 'id'),
      formDirty: state => {
        return !_.isEqual(state.originalData, state.formData)
      },
      // dirtyFields is for debugging purpose
      dirtyFields: state => {
        const o1 = state.originalData
        const o2 = state.formData

        return _.reduce(o1, (result, value, key) =>
          _.isEqual(value, o2[key]) ? result : result.concat(key), [])
      },
      eventBus: () => eventBus
    },
    mutations: {
      masterItemsChanged (state, { masterItems, cursor }) {
        state.masterItems = masterItems
        state.cursor = cursor
      },
      detailChanged (state, { detailData, detailIndex }) {
        Object.assign(state, {
          originalData: detailData,
          detailIndex: detailIndex
        })
      },
      detailUpdated (state, { data, detailIndex }) {
        // TODO: clone?
        Vue.set(state.masterItems, detailIndex, data)
        state.originalData = data
      },
      formDataChanged (state, formData) {
        state.formData = formData
      },
      formInvalidChanged (state, formInvalid) {
        state.formInvalid = formInvalid
      },
      readOnlyChanged (state, readOnly) {
        state.readOnly = readOnly
      },
      cancelChanges (state) {
        state.formData = _.cloneDeep(state.originalData)
      },
      exitDetail (state) {
        state.formData = {}
        state.originalData = {}
        state.detailIndex = null
      },
      supplementalDataAdded (state, supplementalData) {
        state.supplementalData = _.merge(supplementalData, state.supplementalData)
      },
      supplementalDataCleared (state) {
        state.supplementalData = {}
      }
    },
    modules: {
      filter: filterOrchestrator({
        filterDefaults: options.filterDefaults,
        filterDefaultsDiffOmitKeys: options.filterDefaultsDiffOmitKeys
      })
    }
  })
}
