import defaultRestClient from '@/services/clients/rest'
import _ from 'lodash'
import reusePromise from 'reuse-promise'

// TODO: Refactor with GenericCrudService.
// TODO: GenericListResourceService is basically GenericCrudService inside vuex.

async function listItems (resourceName, restClient) {
  try {
    const response = await restClient.get(resourceName)
    return _.get(response, 'data.results', [])
  } catch (error) {
    console.warn(`Error listing ${resourceName}`, error)
    throw error
  }
}
// wrapping listItems in reusePromise avoids load racing condition.
const listItemReusedPromise = reusePromise(listItems)

async function createItem (resourceName, item, restClient) {
  try {
    const response = await restClient.post(resourceName, item)
    // The various API's are inconsistent regarding what gets returned.
    // We'll need to deal with each possibility.
    return response.data.results ? response.data.results[0] : response.data.result
  } catch (error) {
    console.warn(`Error creating ${resourceName} item`, error)
    throw error
  }
}

async function updateItem (resourceName, item, restClient) {
  try {
    const response = await restClient.put(`${resourceName}/${item.id}`, item)
    // The various API's are inconsistent regarding what gets returned.
    // We'll need to deal with each possibility.
    return response.data.results ? response.data.results[0] : response.data.result
  } catch (error) {
    console.warn(`Error updating ${resourceName} item`, error)
    throw error
  }
}

async function deleteItem (resourceName, itemId, restClient) {
  try {
    await restClient.delete(`${resourceName}/${itemId}`)
  } catch (error) {
    console.warn(`Error deleting ${resourceName} item`, error)
    throw error
  }
}

export function itemResult (results) {
  return { results: _.cloneDeep(results) }
}

export function registerListResourceWithStore (resourceName, resourcePath, store, options) {
  if (store.state[resourceName]) throw new Error(`${resourceName} module already registered with store`)

  // Module state is always namespaced and complicates dirty detection,
  // so merge it explicitly.
  const {
    state: extendedState,
    autoLoad,
    restClient = defaultRestClient,
    ...extendedModule
  } = options || {}

  store.registerModule(resourceName, _.merge({
    namespaced: true,
    state () {
      return _.merge({
        loaded: false,
        loading: false,
        items: []
      }, extendedState && extendedState())
    },
    getters: {
      sortedItems: state => sortField => _.sortBy(state.items, sortField),
      itemsById: state => Object.fromEntries(state.items.map(item => [item.id, item]))
    },
    actions: {
      async load (context, forceRefresh) {
        if (context.state.loaded && !forceRefresh) {
          return itemResult(context.state.items)
        }
        if (!context.state.loading) context.commit('loading')
        // Although wrapping in reusePromise avoids making multiple
        // remote calls, it doesn't avoid multiple vuex commits.
        // TODO: Avoid multiple commits in racing condition.
        const items = await listItemReusedPromise(resourcePath, restClient)
        context.commit('load', items)
        // Match return signature of GenericCrudService.list.
        // Clone array so that caller doesn't mutate state.
        // This issue can arise with MasterDetailTable.
        return itemResult(items)
      },
      async create (context, item) {
        const result = await createItem(resourcePath, item, restClient)
        context.commit('create', result)
        // Match return signature of GenericCrudService.create.
        // See comment in load() function why we clone.
      return itemResult([result])
      },
      async update (context, item) {
        const result = await updateItem(resourcePath, item, restClient)
        context.commit('update', result)
        // Match return signature of GenericCrudService.update.
        // See comment in load() function why we clone.
        return itemResult([result])
      },
      async delete (context, itemId) {
        await deleteItem(resourcePath, itemId, restClient)
        context.commit('delete', itemId)
      },
      sideLoad (context, items) {
        context.commit('sideLoad', items)
      }
    },
    mutations: {
      loading (state) {
        state.loading = true
      },
      load (state, items) {
        state.items = items
        state.loaded = true
        state.loading = false
      },
      create (state, item) {
        state.items.push(item)
      },
      delete (state, itemId) {
        state.items = state.items.filter(item => item.id !== itemId)
      },
      update (state, item) {
        const index = state.items.findIndex(i => i.id === item.id)
        if (index > -1) {
          state.items.splice(index, 1, item)
        }
      },
      sideLoad (state, items) {
        state.items = items
        state.loaded = true
      }
    }
  }, extendedModule))

  if (autoLoad) {
    const unwatch = store.watch(
      state => state.organizationActive,
      organizationActive => {
        if (!organizationActive) return
        store.dispatch(`${resourceName}/load`)
        unwatch()
      }
    )
  }
}
