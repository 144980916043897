<template>
  <remote-multiselect
    v-bind="$attrs"
    v-on="forwardListeners"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    :track-by="trackBy"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchItems"
    resourceName="Label"
    :showAddNewItem="enableAddNewItem && canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect'
import LabelFormModal from './LabelFormModal'
import LabelService from './services/LabelService'
import Vue from 'vue'
import { mapGetters } from 'vuex'

const LabelFormModalClass = Vue.extend(LabelFormModal)

export default {
  name: 'LabelSelect',
  inheritAttrs: false,
  props: {
    value: [Array, Number, String],
    labelType: String,
    searchInactive: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
    useTargetId: Boolean,
    enableAddNewItem: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    forwardListeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    forwardValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    fetchItems () {
      // returns a function
      return LabelService.fetchForSelect(this.labelType, this.searchInactive)
    },
    trackBy () {
      return this.useTargetId ? 'targetId' : 'id'
    }
  },
  components: {
    RemoteMultiselect
  },
  methods: {
    addNewItem () {
      // https://stackoverflow.com/a/63578995/1237919
      const modal = new LabelFormModalClass({
        parent: this,
      })
      const propsData = Vue.observable({ itemId: null, labelType: this.labelType })
      modal._props = propsData
      modal.$once('close', () => {
        modal.$el.remove()
        modal.$destroy()
      })
      modal.$once('saved', item => {
        propsData.itemId = item.id
        const newValue = item[this.trackBy]
        this.$emit('input', this.multiple ? (this.value || []).concat(newValue) : newValue)
        this.$emit('item-added', item)
      })
      modal.$mount()
      document.body.appendChild(modal.$el)
    }
  }
}
</script>
