export default {
  name: 'manage-discipline',
  label: 'Attendance',
  link: 'attendance',
  path: 'attendance',
  props: true,
  icon: 'hundred-points',
  description: 'View and edit worker attendance infractions and events.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'manage_discipline',
  requireFeature: 'discipline',
  requireGetter: 'disciplineEnabled',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'discipline-infractions',
      label: 'Infractions',
      title: 'Attendance Infractions',
      path: 'infractions/:view?',
      component: () => import(/* webpackChunkName: "discipline-infractions" */ './DisciplineInfractionList')
    },
    {
      name: 'discipline-events',
      label: 'Events',
      title: 'Attendance Events',
      path: 'events/:view?',
      component: () => import(/* webpackChunkName: "discipline-events" */ './DisciplineEventList')
    },
    {
      path: '',
      redirect: { name: 'discipline-infractions' }
    }
  ]
}
