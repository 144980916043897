import { printWindow } from '@/utils/print'
import PdfExportModal from '@/components/report/PdfExportModal'
import Vue from 'vue'

const PdfExportModalClass = Vue.extend(PdfExportModal)

export default {
  // ManagesGridColumns is a peer mixin dependency
  data () {
    return {
      isPrinting: false
    }
  },
  computed: {
    exportColumnFields () {
      return this.allColumnDefsInPreferredOrder
        .filter(columnDef => !columnDef.hide && !columnDef.hideInExport)
        .map(columnDef => columnDef.field)
    },
    fileName () {
      return this.exportFilename || 'Export'
    }
  },
  methods: {
    print (gridApi) {
      this.enterPrintMode(gridApi)
        .then(() => printWindow(window))
        .then(() => this.exitPrintMode(gridApi))
        .then(() => this.$emit('exported', { type: 'print', data: gridApi.gos.gridOptions.rowData }))
    },

    csv (gridApi) {
      // TODO: Support iOS download -
      // TODO: https://www.ag-grid.com/javascript-grid-export/#export-to-csv-with-ipad
      gridApi.exportDataAsCsv({
        // TODO: ag-grid has indeterministic behavior when specifying columnKeys.
        // TODO: Sometimes the csv returned is blank. I don't know why.
        columnKeys: this.exportColumnFields,
        fileName: `${this.fileName}.csv`
      })
      this.$emit('exported', { type: 'csv', data: gridApi.gos.gridOptions.rowData })
    },

    excel (gridApi) {
      import(/* webpackChunkName: "xlsx" */ '@sheet/core')
        .then(module => {
          const xlsx = module.default

          const csv = gridApi.getDataAsCsv({
            columnKeys: this.exportColumnFields
          })
          // Parse raw values, because it doesn't parse our datetime string formats correctly.
          const workbook = xlsx.read(csv, { type: 'string', raw: true, cellStyles: true })
          const xlsxContent = xlsx.write(workbook, { bookType: 'xlsx', type: 'base64', cellStyles: true })

          this.download(`${this.fileName}.xlsx`, xlsxContent)
        })
        .then(() => this.$emit('exported', { type: 'xlsx', data: gridApi.gos.gridOptions.rowData }))
    },

    pdf (params) {
      const modal = new PdfExportModalClass({
        parent: this,
        propsData: {
          showFitWidthToPage: false
        }
      })
      modal.$once('ok', exportPreferences => {
        const printParams = {
          PDF_HEADER_COLOR: "#f8f8f8",
          PDF_INNER_BORDER_COLOR: "#dde2eb",
          PDF_OUTER_BORDER_COLOR: "#babfc7",
          PDF_LOGO: '', // `${location.origin}/img/logo.png`,
          PDF_PAGE_ORIENTATION: exportPreferences.pageOrientation.toLowerCase(),
          PDF_WITH_HEADER_IMAGE: false, // exportPreferences.includeTitles,
          PDF_HEADER_TEXT: exportPreferences.includeTitles ? params.title : null,
          PDF_WITH_FOOTER_PAGE_COUNT: true,
          PDF_HEADER_HEIGHT: 25,
          PDF_ROW_HEIGHT: 15,
          PDF_ODD_BKG_COLOR: "#fcfcfc",
          PDF_EVEN_BKG_COLOR: "#ffffff",
          PDF_WITH_CELL_FORMATTING: true,
          PDF_WITH_COLUMNS_AS_LINKS: true,
          PDF_SELECTED_ROWS_ONLY: false
        }
        import(/* webpackChunkName: "print-pdf" */ '@/pdfExport/printDoc')
        .then(module => {
          const printPdf = module.default
          return printPdf(this.fileName, printParams, params.agGridApi)
        })
        .then(() => this.$emit('exported', { type: 'pdf', data: params.agGridApi.gos.gridOptions.rowData }))
        // TODO: Catch error and show message.
      })
      modal.show()
    },

    download (fileName, content) {
      fileName = fileName || 'noWarning.xlsx'
      const blobObject = this.b64toBlob(content, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')

      if (window.navigator.msSaveOrOpenBlob) {
        // Internet Explorer
        window.navigator.msSaveOrOpenBlob(blobObject, fileName)
      } else {
        // Chrome
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blobObject)
        downloadLink.download = fileName

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    },

    // http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    b64toBlob (b64Data, contentType) {
      const sliceSize = 512
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },

    enterPrintMode (gridApi) {
      this.isPrinting = true

      document.body.classList.add('printing-page')

      gridApi.setDomLayout('print')

      return new Promise(resolve => {
        // TODO: Try to use ag-grid's AnimationQueueEmptyEvent instead of polling.
        // TODO: Disable export button while we're waiting here.
        const checkAnimationFrameQueue = () => {
          if (gridApi.isAnimationFrameQueueEmpty()) {
            // Documentation sample waits 2 seconds:
            // https://www.ag-grid.com/javascript-grid-for-print/#example-for-print-complex
            setTimeout(resolve, 2000)
          } else {
            setTimeout(checkAnimationFrameQueue, 100)
          }
        }
        checkAnimationFrameQueue()
      })
    },

    exitPrintMode (gridApi) {
      document.body.classList.remove('printing-page')
      gridApi.setDomLayout()
      this.isPrinting = false
    }
  }
}
