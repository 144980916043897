export default function () {
  return {
    version: process.env.VUE_APP_BUILD_VERSION,
    development: process.env.VUE_APP_DEVELOPMENT === 'true',
    legacyMinify: process.env.VUE_APP_LEGACY_MINIFY === 'true',
    staticPath: process.env.VUE_APP_LEGACY_URL,
    auditServer: process.env.VUE_APP_AUDIT_SERVER,
    billingServer: process.env.VUE_APP_BILLING_SERVER,
    loginServer: process.env.VUE_APP_LOGIN_SERVER,
    messagingServer: process.env.VUE_APP_MESSAGING_SERVER,
    notificationServer: process.env.VUE_APP_NOTIFICATION_SERVER,
    payrollServer: process.env.VUE_APP_PAYROLL_SERVER,
    restServer: process.env.VUE_APP_REST_SERVER,
    wwwServer: process.env.VUE_APP_WWW_SERVER,
    firebaseApiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    firebaseAuthDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    firebaseAppId: process.env.VUE_APP_FIREBASE_APP_ID,
    firebaseMeasurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    firebaseMessagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    firebaseVapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    projectId: process.env.VUE_APP_PROJECT_ID,
    stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    bootstrap: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  }
}
