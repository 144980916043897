<template>
    <b-modal
    ref="modal"
    centered
    modal-class="feedback-modal"
    @hidden="onHidden"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    :ok-disabled="submitting || (!submitted && !hasFeedback)"
    :ok-only="submitted"
    :ok-title="submitted ? 'Close' : 'Submit'"
    :cancel-disabled="submitting"
    title="Submit Feedback"
    @ok="ok"
  >
    <b-form-group label="Click to Rate">
      <star-rating
        v-model="rating"
        :show-rating="false"
        :star-size="40"
        :read-only="submitting || submitted"
      />
    </b-form-group>
    <b-form-group label="Comments">
      <b-form-textarea
        v-model="comments"
        :rows="5"
        no-resize
        :disabled="submitting || submitted"
      ></b-form-textarea>
    </b-form-group>
    <div v-if="submitting" key="submitting" class="submitting">
      <font-awesome-icon icon="circle-notch" spin />
      <span>Submitting...</span>
    </div>
    <div v-if="errorMessage" class="error-message" key="error-message">
      <font-awesome-icon icon="triangle-exclamation" />
      <span>{{ errorMessage }}</span>
    </div>
    <div v-if="submitted" class="submitted" key="submitted">
      <font-awesome-icon icon="circle-check" />
      <span>Thanks for the feedback!</span>
    </div>
  </b-modal>
</template>
<script>
import service from '@/modules/dashboard/services/FeedbackService'
import { extractErrorMessage} from '@/utils/misc'
import StarRating from 'vue-star-rating'

export default {
  name: 'FeedbackModal',
  components: {
    StarRating
  },
  data () {
    return {
      submitting: false,
      submitted: false,
      errorMessage: null,
      rating: null,
      comments: null
    }
  },
  computed: {
    hasFeedback () {
      return !!this.rating || this.comments
    }
  },
  methods: {
    onHidden () {
      if (this.$el) this.$el.remove()
      this.$destroy()
    },
    close () {
      this.onHidden()
    },
    ok (bvModalEvent) {
      if (this.submitted) return
      bvModalEvent.preventDefault()
      this.submitting = true
      this.errorMessage = null
      service.submitFeedback(this.rating, this.comments)
        .then(() => { this.submitted = true })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.submitting = false })
    }
  },
  mounted () {
    this.$refs.modal.show()
  }
}
</script>
<style lang="scss" scoped>
@import "~scss/variables";

.feedback-modal {
  svg {
    margin-right: .5rem;
  }
  .submitting {
    color: $flat-ui-peter-river;
    text-align: center;
  }
  .submitted {
    color: $flat-ui-emerald;
    text-align: center;
  }
}
</style>
