<template>
  <b-modal
    ref="modal"
    title="Save Changes"
    ok-title="Yes"
    cancel-title="No"
    no-close-on-backdrop
    no-close-on-esc
    centered
    @ok="yes"
    @cancel="no"
    @hide="close"
  >
    <p>
      This form has unsaved changes. Do you wish to save them before continuing?
    </p>
  </b-modal>
</template>
<script>

export default {
  mounted () {
    this.isMounted = false
  },
  methods: {
    yes () {
      this.$emit('close', true)
    },
    no () {
      this.$emit('close', false)
    },
    close () {
      this.$emit('close', undefined)
    },
    show () {
      if (!this.isMounted) {
        this.$mount()
        document.body.appendChild(this.$el)
        this.$refs.modal.show()
        this.isMounted = true
      }

      this.$refs.modal.show()
    },
    onHidden () {
      if (this.$el) this.$el.remove()
      this.isMounted = false
      this.$destroy()
    }
  }
}
</script>
