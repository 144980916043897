<template>
  <span>
    <font-awesome-icon ref="icon" :icon="icon" :class="$style.icon" />
    <b-popover :target="() => $refs.icon"
      triggers="hover focus"
      :custom-class="`${$style['hover-popover']} ${extraClass}`"
    >
          <slot>{{ hoverText }}</slot>
    </b-popover>
  </span>
</template>
<script>

export default {
  name: 'HoverIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    hoverText: {
      type: String,
      default: ''
    },
    extraClass: String
  }
}
</script>
<style lang="scss" module>
  .hover-popover {
    :global(.popover-body) {
      overflow-y: auto;
      white-space:pre-wrap;
      max-height: 80vh;
    }
  }
  .icon {
    margin-left: 5px;
    color: #555;
    cursor: help;
  }
</style>
