<template>
  <b-form-group
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :label="label"
    :label-class="labelClass"
    :label-cols="labelCols"
    :label-cols-sm="labelColsSm"
    :label-cols-md="labelColsMd"
    :label-cols-lg="labelColsLg"
    :label-cols-xl="labelColsXl"
    :label-for="labelFor"
    :description="description"
  >
    <!-- Pass on named label slot -->
    <slot name="description" slot="description"/>
    <slot name="label" slot="label"/>
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch && preferredValidator.$touch() }"
    />
  </b-form-group>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'FormElement',
  extends: singleErrorExtractorMixin,
  // TODO: Why can't we just use $attrs.labelCols?
  props: ['labelFor', 'labelClass', 'labelCols', 'labelColsSm', 'labelColsMd', 'labelColsLg', 'labelColsXl', 'description'],
  mounted () {
    // Touch validator on mount in order to initialize validation for
    // KeyMultiselect component. Without this call, the @input event doesn't
    // fire initially for some reason. It may have to do with KeyMultiselect's
    // forwardListeners swallowing the input event, but I'm not sure how else to
    // deal with that.
    this.preferredValidator.$touch()
  }
}
</script>
