<template>
  <div>
    <keep-alive :include="includeInKeepAlive">
      <router-view v-if="routesToChild"></router-view>
      <table-of-contents v-else />
    </keep-alive>
  </div>
</template>
<script>
import RouterViewMeta from '@/mixins/RouterViewMeta'
import TableOfContents from './TableOfContents'

export default {
  name: 'RouterPassThrough',
  mixins: [RouterViewMeta],
  inheritAttrs: false,
  components: {
    TableOfContents
  },
  data () {
    return {
      // Initialize to true, because an initial incorrect false
      // can cause table of contents template to have binding errors.
      routesToChild: true
    }
  },
  watch: {
    activeRoute (activeRoute) {
      if (activeRoute) this.checkIfRoutesToChild()
    }
  },
  methods: {
    checkIfRoutesToChild () {
      const routedInstance = this.$route.matched.slice(-1)[0].instances.default
      this.routesToChild = !routedInstance || this._uid !== routedInstance._uid
      // console.log(`RouterPassThrough routes to child: ${this.routesToChild}`)
    }
  },
  mounted () {
    this.checkIfRoutesToChild()
  }
}
</script>
