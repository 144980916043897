<template>
    <span
      :class="[variant, canClick ? 'clickable' : null]"
      @click="canClick ? clickHandler(value) : null"
    >
      <font-awesome-icon v-if="icon" :icon="icon" :spin="spin" />
      <span class="text" v-if="text">{{ text }}</span>
    </span>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'FontAwesomeCellRenderer',
  computed: {
    value () {
      return this.params.valueFormatted || this.params.value
    },
    icon () {
      const params = this.params
      const value = this.value
      if (value?.icon) return value.icon
      if (params?.value === true && params.trueIcon) return params.trueIcon
      if (params?.value === false && params.falseIcon) return params.falseIcon
      if (params.icon) return params.icon
      return null
    },
    text () {
      const value = this.value
      return value && value.text
    },
    spin () {
      const value = this.value
      return value && value.spin
    },
    variant () {
      const value = this.value
      return value && value.variant
    },
    clickHandler () {
      return this.params.clickHandler
    },
    clickDisabled () {
      return !!this.value.clickDisabled
    },
    canClick () {
      return !!this.clickHandler && !this.clickDisabled
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~scss/variables";

.clickable {
  cursor: pointer;
}

.error {
  color: $flat-ui-alizarin;
}
.warning {
  color: $flat-ui-sunflower;
}
.success {
  color: $flat-ui-emerald;
}
.info {
  color: $flat-ui-peter-river;
}
.waiting {
  color: $fc-logo-orange;
}

.text {
  margin-left: .5rem;
}
</style>
