import ExportsGrid from '@/mixins/ExportsGrid'
import ManagesAgGridColumns from '@/mixins/ManagesAgGridColumns'
import _ from 'lodash'
import FontAwesomeCellRenderer from '@/components/grid/FontAwesomeCellRenderer'
import OverlayLoading from '@/components/grid/OverlayLoading'
import NoRowsOverlay from '@/components/grid/NoRowsOverlay'
import { extractErrorMessage } from '@/utils/misc'

export default {
  mixins: [
    ExportsGrid,
    ManagesAgGridColumns
  ],
  components: {
    fontAwesomeCellRenderer: FontAwesomeCellRenderer,
    OverlayLoading,
    noRowsOverlay: NoRowsOverlay
  },
  props: {
    activeRoute: Object,
    crudService: Object,
    orchestrator: String,
  },
  data () {
    return {
      filterOpen: false,
      rowData: [],
      cursor: null,
      loading: false,
      loadedOnce: false,
      loadErrorMessage: null,
      gridApi: null,
      gridOptions: {},
      // Used to maintain selection state when row items are changed.
      savedSelectedItemIds: null,
      customizePinned: false
    }
  },
  computed: {
    loadingFresh () {
      return this.gridApi && this.loading && this.rowData.length < 1
    },
    loadingMoreResults () {
      return this.loading && this.rowData.length > 0
    },
    loadedNoRows () {
      return !this.loading && this.rowData.length === 0
    },
    filterParams () {
      return this.$store.state[this.orchestrator].filter.filterParams
    },
    searchInputText () {
      return this.$store.state[this.orchestrator].filter.searchInputText
    },
    filterAppearsInUse () {
      return this.$store.getters[`${this.orchestrator}/filter/filterAppearsInUse`]
    },
    filterInUse () {
      return this.$store.getters[`${this.orchestrator}/filter/filterInUse`]
    },
    filterOrSearchInUse () {
      return this.$store.getters[`${this.orchestrator}/filter/filterOrSearchInUse`]
    },
    filterFormData () {
      return this.$store.state[this.orchestrator].filter.filterFormData
    },
    pluralResourceName () {
      return this.$store.getters[`${this.orchestrator}/pluralResourceName`]
    },
    exportFilename () {
      return this.pluralResourceName
    },
    supplementalData () {
      return this.$store.state[this.orchestrator].supplementalData
    }
  },
  watch: {
    loadingFresh (newValue) {
      if (!this.gridApi) return // ag-grid still initializing

      if (newValue) {
        // Delay showLoadingOverlay to avoid racing condition with search() clearing rowData
        this.$nextTick(() => this.gridApi.showLoadingOverlay())
      } else if (this.rowData.length > 0) {
        this.gridApi.hideOverlay()
      }
    },
    loadedNoRows () {
      this.considerNoRowsOverlay()
    },
    'activeRoute.params.view': {
      handler (view) {
        this.onActiveRouteViewChange(view)
      },
      immediate: true
    },
    filterOpen (value) {
      const action = value ? 'show' : 'hide'
      this.$store.dispatch(`${this.orchestrator}/filter/${action}`)
    }
  },
  methods: {
    // We react specifically on the view and not the entire route, in order to support
    // child routes changing (e.g., pay summary).
    onActiveRouteViewChange (view) {

      if (!this.routeHandledAtleastOnce) {
        this.routeHandledAtleastOnce = true
        if (!view && this.filterOrSearchInUse) {
          // restore filter from vuex to component
          // TODO: It might be nice to also restore rowData from vuex, since it's there.
          // TODO: But it's a bit involved. We'd also need to store cursor in vuex.
          this.applyFilter(true)
          return
        }
        this.forceRefresh = true
      }

      let forceRefresh = this.forceRefresh
      this.forceRefresh = false

      if (view || forceRefresh) {
        forceRefresh = true

        let filterParams
        if (view) {
          try {
            filterParams = JSON.parse(view)
          } catch (e) {
            console.warn(`Invalid filter string on url: ${view}`)
            filterParams = {}
          }
        } else {
          filterParams = {}
        }
        this.$store.dispatch(`${this.orchestrator}/filter/setFilterParams`, filterParams)
      }

      if (forceRefresh) {
        this.search()
      }
    },

    applySearch (searchText) {
      this.$store.dispatch(`${this.orchestrator}/filter/setSearchText`, searchText)
      this.applyFilter(true)
    },

    applyFilter (force) {
      this.$store.dispatch(`${this.orchestrator}/filter/applyForm`)

      const filterString = this.filterOrSearchInUse ? JSON.stringify({
        ...this.filterParams
      }) : null
      // If route hasn't changed but we've never loaded data, then call search() directly.
      const emptyApplyOnNeverLoadedData = !filterString && !this.loadedOnce

      // The following !!activeRoute.params.view condition handles the case where we are currently on the new detail form,
      // and have never loaded any data.
      if (this.activeRoute && (!emptyApplyOnNeverLoadedData || !!this.activeRoute.params.view)) {
        this.forceRefresh = !this.filterOrSearchInUse && force
        this.$router.push({ name: this.routeName, params: { view: filterString } }).catch(() => {})
      } else {
        this.search()
      }
    },

    clearFilter () {
      this.$store.dispatch(`${this.orchestrator}/filter/clearForm`)
    },

    setRowData (rowData, cursor) {
      this.saveSelectionState()
      this.rowData = rowData
      this.cursor = cursor
      this.emitRowDataToStore()
    },

    emitRowDataToStore () {
      this.$store.commit(`${this.orchestrator}/masterItemsChanged`, {
        masterItems: this.rowData,
        cursor: this.cursor
      })
    },

    search () {
      if (!this.customizePinned) {
        this.filterOpen = false // if was open, then close
      }
      // TODO: Is there a higher level vuex mutation that can do all this?
      this.cursor = null
      this.$store.commit(`${this.orchestrator}/supplementalDataCleared`)
      this.setRowData([])
      this.loadNextPage()
    },

    loadNextPage (limit) {
      if (!this.crudService) return
      if (limit === 'all') {
        this.loadAllRemainingResults()
        return
      }

      this.loadedOnce = true
      this.loading = true
      this.loadErrorMessage = null

      const filterParams = _.cloneDeep(this.filterParams)
      const transformedFilterParams = this.transformFilterParams
        ? this.transformFilterParams(filterParams)
        : filterParams

      return this.crudService.list({
        cursor: this.cursor,
        limit,
        ...transformedFilterParams
      })
        .then(data => {
          if (!_.isEqual(filterParams, this.filterParams)) {
            console.log('Filter params changed during request. Ignoring.')
            return
          }

          const api = this.gridOptions.api
          // first get current last row
          const lastRowIndex = this.rowData.length ? this.rowData.length - 1 : 0

          // Some services return supplemental data;
          // others have already merged it into the results.
          this.$store.commit(`${this.orchestrator}/supplementalDataAdded`, data.supplementalData)

          this.setRowData(this.rowData.concat(data.results), data.cursor)

          // scroll to first row of new page
          if (lastRowIndex) {
            const newRowIndex = data.results.length ? lastRowIndex + 1 : lastRowIndex
            // I don't know why, but Bugsnag sometimes shows that api is undefined
            this.$nextTick(() => api && api.ensureIndexVisible(newRowIndex, 'bottom'))
          }

          // if an item is currently selected, then look up new index for navigation
          if (this.editingItem) {
            this.editingIndex = this.rowData.findIndex(item => item.id === this.editingItem.id)
          }
        })
        .catch(error => this.loadErrorMessage = extractErrorMessage(error ))
        .finally(() => { this.loading = false })
    },

    async loadAllRemainingResults () {
      while (this.cursor) {
        try {
          // Load up to 500 per request.
          await this.loadNextPage(500)
          if (this.loadErrorMessage) {
            console.warn('An error occurred in loadAllRemainingResults while loading a page, so aborting.')
            break
          }
        } catch (e) {
          // loadNextPage catches all errors, so we should never reach here.
        }
      }
    },

    findIndexOfColumnPref (fieldName) {
      // Sometimes column pref is just the field name; sometimes its object containing field name and other properties.
      return this.columnPrefs.findIndex(cp => cp === fieldName || cp.field === fieldName)
    },

    considerNoRowsOverlay () {
      // TODO: Fix loading loadingOverlayComponentParams not reactive, so we can re-enable if-condition below.
      if (this.loadedNoRows && /*!this.loadErrorMessage &&*/ this.gridApi) {
        this.gridApi.showNoRowsOverlay()
      }
    },

    saveSelectionState () {
      // TODO: racing conditions?
      this.savedSelectedItemIds = this.gridOptions.rowSelection
        // TODO: Have seen error on users page right after navigating away to dashboard:
        // TODO: Cannot read property 'getSelectedNodes' of null
        ? new Set(this.gridOptions.api.getSelectedNodes().map(n => n.data.id))
        : null
    },

    restoreSelectionState () {
      if (this.gridOptions.rowSelection && this.savedSelectedItemIds) {
        this.gridOptions.api.forEachNode(node => node.setSelected(this.savedSelectedItemIds.has(node.data.id)))
        this.savedSelectedItemIds = null
      }
    }
  },
  beforeMount () {
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      onGridReady: event => {
        this.gridApi = event.api
        this.considerNoRowsOverlay()
      },
      getRowId: data => data.data.id,
      // TODO: Were we using this when trying out ag-grid excel export?
      // defaultExportParams: {
      //   // This is necessary for sheetJs to read the resultant file
      //   suppressTextAsCDATA: true
      // },
      context: {
        componentParent: this
      },
      loadingOverlayComponent: 'OverlayLoading'
    }
  }
}
