
export const MinutesPerDay = 24 * 60

// isPayCodeSetBasic is used to determine whether a pay code rule's pay codes
// can be displayed in basic mode or not.
export function isPayCodeSetBasic (rule) {
  return rule.daily1PayCode === rule.weekly1PayCode &&
    rule.daily1PayCode === rule.consecutiveDaysRegularPayCode &&
    rule.daily2PayCode === rule.weekly2PayCode &&
    rule.daily2PayCode === rule.consecutiveDaysDaily1PayCode &&
    !rule.daily1Threshold
}

export function NewPayCodeFactory () {
  return {
    id: null,
    name: null,
    multiplierDifferential: 1,
    fixedDifferential: 0,
    addThenMultiply: false,
    minBaseWage: false,
    countsTowardOt: true
  }
}

// Pass in payrollDefaults only if you want to also check that the pay classes
// are compatible with the defaults. Otherwise, don't pass in payrollDefaults.
export function payClassesAreIntercompatible (payClasses, payrollDefaults) {

  // reduction fields
  let payPeriod = payrollDefaults && payrollDefaults.payPeriodType
  let payPeriodStartsOn = payrollDefaults && payrollDefaults.payPeriodStarts
  let applyShiftOverlapTo = payrollDefaults && payrollDefaults.applyShiftOverlapTo
  let payMode
  let overtimeByPayPeriod
  let hasPeriodOvertime
  let weekStartsOn

  return payClasses.every(payClass => {
    const thisHasPeriodOvertime = !!(payClass.weekly1Threshold || payClass.consecutiveDaysThreshold)

    if (!payPeriod) payPeriod = payClass.payPeriod
    if (!payPeriodStartsOn) payPeriodStartsOn = payClass.payPeriodStartsOn
    if (!applyShiftOverlapTo) applyShiftOverlapTo = payClass.applyShiftOverlapTo
    if (!payMode) payMode = payClass.payMode
    if (overtimeByPayPeriod === undefined) overtimeByPayPeriod = payClass.overtimeByPayPeriod
    if (hasPeriodOvertime === undefined) hasPeriodOvertime = thisHasPeriodOvertime
    if (weekStartsOn === undefined) weekStartsOn = payClass.weekStartsOn

    return (
      payClass.payPeriod === payPeriod &&
      payClass.payPeriodStartsOn === payPeriodStartsOn &&
      payClass.applyShiftOverlapTo === applyShiftOverlapTo &&
      payClass.payMode === payMode &&
      payClass.overtimeByPayPeriod === overtimeByPayPeriod &&
      // We don't require that hasPeriodOvertime be the same for all pay classes,
      // because the backend will rewind to period start as long as any pay class
      // has period overtime. We just check that if both do have period overtime
      // that they have the same week_starts_on.
      (!hasPeriodOvertime || !thisHasPeriodOvertime || overtimeByPayPeriod || payClass.weekStartsOn === weekStartsOn)
    )
  })
}

export const NET_PAY_SCHEDULE_OPTIONS = [
  { label: 'Every Pay Period', value: 'EVERY'},
  { label: '1st Pay Period of Month', value: 'FIRST'},
  { label: '2nd Pay Period of Month', value: 'SECOND'},
  { label: '3rd Pay Period of Month', value: 'THIRD'},
  { label: '4th Pay Period of Month', value: 'FOURTH'},
  { label: 'Last Pay Period of Month', value: 'LAST'},
]
