<template>
  <header class="app-header navbar">
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" @click="mobileSidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <b-link class="navbar-brand" to="/orgs"></b-link>
    <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" @click="sidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <breadcrumb/>
    <div class="ml-auto" />
    <notification-bell v-if="dashboardNotificationsEnabled" />
    <help-header-dropdown />
    <user-header-dropdown/>
  </header>
</template>
<script>
import UserHeaderDropdown from './UserHeaderDropdown'
import Breadcrumb from './Breadcrumb'
import HelpHeaderDropdown from '../components/HelpHeaderDropdown'
import NotificationBell from '../components/NotificationBell'
import { mapState } from 'vuex'

export default {
  name: 'c-header',
  inheritAttrs: false,
  components: {
    Breadcrumb,
    UserHeaderDropdown,
    HelpHeaderDropdown,
    NotificationBell
  },
  computed: {
    ...mapState({
      dashboardNotificationsEnabled: state => state.dashboardNotifications.running
    })
  },
  methods: {
    sidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
    },
    mobileSidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/style';

.app-header {
  justify-content: flex-start;
  flex-wrap: nowrap;
}

// Change default core-ui behavior such that when below lg width,
// the brand will be minimized even if navbar is not.
@include media-breakpoint-down(lg) {
  .app-header.navbar {
    .navbar-brand {
      width: $navbar-brand-minimized-width;
      background-color: $navbar-brand-minimized-bg;
      background-image: $navbar-brand-minimized-logo;
      background-size: $navbar-brand-minimized-logo-size;
      @include borders($navbar-brand-minimized-border);
    }
  }
}

</style>
