<template>
  <b-navbar-nav class="help-header-dropdown">
    <b-nav-item-dropdown right no-caret>
      <template slot="button-content">
        <font-awesome-icon icon="circle-question" />
      </template>
      <b-dropdown-item @click="launchFeedback"><font-awesome-icon icon="message-exclamation" class="icon" /> Feedback</b-dropdown-item>
      <b-dropdown-item @click="launchHelp"><font-awesome-icon icon="question" class="icon" /> Help</b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>
<script>
import _ from 'lodash'
import { toggleZendesk }  from '@/utils/zendesk'
import FeedbackModal from './FeedbackModal'
import Vue from 'vue'

const FeedbackModalClass = Vue.extend(FeedbackModal)

export default {
  name: 'HelpHeaderDropdown',
  methods: {
    launchFeedback () {
      const modal = new FeedbackModalClass({ parent: this })
      modal.$mount()
    },
    launchHelp () {
      toggleZendesk(this.$store)
    }
  }
}
</script>
<style lang="scss" scoped>

.icon {
  width: 1rem;
  margin-right: .75rem;
}
.fa-circle-question {
  font-size: 22px;
}
</style>
