import Vue from 'vue'

const defaultEvents = ['mousedown', 'touchstart']

//
// binding value can be one of:
//   - falsey value (disable)
//   - function value (callback)
//   - object containing:
//      1) 'enable' boolean
//      2) 'handler' function
//
// TODO: Consider using this project instead:
// TODO: https://github.com/ndelvalle/v-click-outside

function bind (el, binding, vNode) {
  let enable = null
  let handler = null
  // TODO: add option to specify which events to listen to
  // TODO: will also need to check if bound events changes
  let events = defaultEvents

  if (!binding.value) {
    enable = false
  } else if (binding.value instanceof Function) {
    enable = true
    handler = binding.value
  } else if (binding.value instanceof Object) {
    if (typeof (binding.value.enable) === 'boolean' &&
        binding.value.handler instanceof Function) {
      enable = binding.value.enable
      handler = binding.value.handler
    } else {
      throw Error('click-outside directive object must contain an "enable" boolean and a "handler" function')
    }
  } else {
    throw Error('click-outside directive object must contain either a falsey value, a function handler, or an object of options')
  }

  if (!enable) {
    unbind(el)
    return
  }

  // if handler changed, then unbind old handler
  if (el.clickOutsideHandler && el.clickOutsideHandler !== handler) {
    unbind(el)
  }

  // bind to current options

  function clickOutsideCheck (event) {
    event.stopPropagation()

    if (!el.contains(event.target)) {
      handler()
    }
  }

  events.forEach(event => {
    document.addEventListener(event, clickOutsideCheck)
  })

  el.clickOutsideCheck = clickOutsideCheck
  el.clickOutsideHandler = handler
  el.clickOutsideEvents = events
}

function unbind (el) {
  if (el.clickOutsideCheck && el.clickOutsideEvents) {
    el.clickOutsideEvents.forEach(event => {
      document.removeEventListener(event, el.clickOutsideCheck)
    })
    el.clickOutsideCheck = null
    el.clickOutsideEvents = null
    el.clickOutsideHandler = null
  }
}

Vue.directive('click-outside', {
  bind: bind,
  update: bind,
  unbind: unbind
})
