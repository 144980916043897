<template>
  <spinner class="mask-spinner" height="100%" />
</template>
<script>
import Spinner from './Spinner'
export default {
  components: {
    Spinner
  }
}
</script>
<style lang="scss" scoped>
.mask-spinner {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #bbb;
  opacity: 0.5;

  ::v-deep .spinner {
    color: black;
  }
}
</style>
