<template>
  <div :class="[$style['overlay-loading'], 'ag-overlay-loading-center']">
    <template v-if="errorMessage">
      <div :class="$style['error-message']">
        <font-awesome-icon icon="triangle-exclamation" />
        {{ errorMessage }}
      </div>
      <b-btn variant="outline-primary" @click="retry">
        <font-awesome-icon icon="cloud-download-alt" />
        Try again
      </b-btn>
    </template>
    <template v-else>
      <font-awesome-icon icon="circle-notch" spin />
      Loading...
    </template>
  </div>
</template>
<script>
export default {
  name: 'OverlayLoading',
  computed: {
    errorMessage () {
      return this.params.errorMessage
    }
  },
  methods: {
    retry () {
      this.params.retry()
    }
  }
}
</script>
<style lang="scss" module>
@import "~scss/variables";

.overlay-loading {
  .error-message {
    color: $flat-ui-alizarin;
  }
  :global .btn {
    margin-top: 25px;
  }
  :global svg {
    margin-right: .25rem;
  }
}
</style>
