import Vue from 'vue'

export function openUserFormModal (orgUserId, parent) {
  import(/* webpackChunkName: "user-form-modal" */ './EmployeeFormModal')
    .then(module => {
      const ModalClass = Vue.extend(module.default)
      const modal = new ModalClass({
        parent
      })
      const propsData = Vue.observable({ itemId: orgUserId })
      modal._props = propsData
      modal.$once('close', () => {
        modal.$el.remove()
        modal.$destroy()
      })
      modal.$mount()
      document.body.appendChild(modal.$el)
    })
}
