export const TimeEntryExceptionMap = {
  'added': 'A',
  'cred': 'C',
  'edited': 'E',
  'face': 'F',
  'geo': 'G',
  'geo_error': 'G',
  'offline': 'O',
  'punched': 'P',
  'punch_attempted': 'PA',
  'tampered': 'T',
  'missed': 'M',
  'started_early': 'SE',
  'started_late': 'SL',
  'missed_break': 'MB',
  'short_break': 'SB',
  'long_break': 'LB',
  'left_early': 'LE',
  'left_late': 'LL',
  'out_of_shift': 'OS',
  'prior_shift': 'PS',
  'next_shift': 'NS',
  'multi_shift': 'MS',
  'day_off': 'DO',
  // TODO: ★ not showing in sync fusion grid pdf export
  'flagged': '★'
}
