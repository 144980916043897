<template>
  <b-modal
    ref="modal"
    :title="`Import ${listResourceName}`"
    centered
    hide-footer
    hide-header-close
    @hidden="onHidden"
  >
    <p>
      Please email your spreadsheet to <a href="mailto:support@fareclock.com">support@fareclock.com</a>,
      and we will be happy to bulk import it for you.
    </p>
  </b-modal>
</template>
<script>

const defaultListResourceName = 'List'

export default {
  data () {
    return {
      listResourceName: defaultListResourceName
    }
  },
  mounted () {
    this.isMounted = false
  },
  methods: {
    show (listResourceName) {
      this.listResourceName = listResourceName || defaultListResourceName

      if (!this.isMounted) {
        this.$mount()
        document.body.appendChild(this.$el)
        this.$refs.modal.show()
        this.isMounted = true
      }

      this.$refs.modal.show()
    },
    onHidden () {
      if (this.$el) this.$el.remove()
      this.isMounted = false
      this.$destroy()
    }
  }
}
</script>
