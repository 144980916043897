import axios from 'axios'
import delay from 'delay'
import store from '@/store'
import constants from '@/constants'
import _ from 'lodash'

const baseURL = constants().restServer

const instance = axios.create({
  baseURL
})

export function getBaseURL (orgMode, orgId) {
  const applyOrgMode = orgMode || 'required'
  if (orgMode !== 'none') {
    const applyOrgId = orgId || store.state.organizationId
    if (!applyOrgId && applyOrgMode === 'required') {
      throw new Error('Can not make request without organization ID')
    }
    if (applyOrgId) {
      return `${baseURL}/orgs/${applyOrgId}`
    }
  }
  return baseURL
}

instance.interceptors.request.use(function (config) {
  const auth = config.auth !== false
  const jwtType = config.jwtType || (config.orgMode === 'none' ? 'idaasJwt' : 'orgAccessJwt')

  return Promise.resolve()
    .then(() => {
      if (!auth) return
      // Forward org id for orgAccessJwt, if specified.
      return store.getters[`jwt/${jwtType}`](config.orgId)
        .then(jwt => {
          config.headers.Authorization = `${store.getters['jwt/authHeaderPrefix']} ${jwt}`
        })
    })
    .then(() => {
      try {
        config.baseURL = getBaseURL(config.orgMode, config.orgId)
      } catch (error) {
        return Promise.reject(error)
      }
      return config
    })
})

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    if (_.get(error.response, 'data.detail') === 'Organization login requirement not met') {
      store.dispatch('orgService/failedOrgAuthRequirement')
    }
  }
  return Promise.reject(error)
})

// Mix in async/poll reporting functionality.

function initiateAsyncRequest (resourceName, params) {
  return instance.post(`${resourceName}/async`, null, { params })
    .then(response => response.data.reportKey)
}

function singlePoll (resourceName, reportKey) {
  return instance.get(`${resourceName}/async/${reportKey}/poll`)
}

async function poll (resourceName, reportKey, timeout, interval = 3000) {
  const now = new Date()

  for (;;) {
    // TODO: Backoff each iteration?
    await delay(interval)

    const response = await singlePoll(resourceName, reportKey)

    if (response.status === 200) {
      return response
    }
    if ((+new Date() - +now) > (timeout * 1000) - interval) {
      throw new Error('Timed out')
    }
  }
}

instance.listAsync = function (resourceName, params, timeout = 600 /* max seconds to poll */) {
  return (params.reportKey
    ? Promise.resolve(params.reportKey)
    : initiateAsyncRequest(resourceName, params)).then(reportKey => poll(resourceName, reportKey, timeout))
}

export default instance
