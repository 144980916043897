<template>
  <b-modal ref="modal"
    title="Confirm Save"
    ok-title="Save"
    @ok="save"
    @cancel="cancel"
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <font-awesome-icon icon="circle-info" />
    You have changed the following fields which affect your organization’s existing time cards:
    <ul>
      <li v-for="field in fields" :key="field">{{ field }} </li>
    </ul>
    You can choose to retroactively update all your existing time cards with these changes,
    or only apply it to future time cards.<br/>
    <br/>
    <b-form-checkbox v-model="checked">
      Apply these changes retroactively<br/>
      <span class="disclaimer">* retroactive changes may take several minutes to update</span>
    </b-form-checkbox>
  </b-modal>
</template>
<script>
export default {
  name: 'DenormPrompt',
  props: {
    fields: Array
  },
  data () {
    return {
      checked: true
    }
  },
  methods: {
    save () {
      this.$emit('save', this.checked)
    },
    cancel () {
      this.$emit('cancel')
    },
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>
<style lang="scss" scoped>

  .disclaimer {
    font-style: italic;
    font-size: 0.75rem;
  }

</style>
