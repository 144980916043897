export default {
  data () {
    return {
      allTabsExpanded: false
    }
  },
  computed: {
    accordionId () {
      return this.allTabsExpanded ? null : 'my-accordion'
    }
  },
  methods: {
    toggleAllTabs () {
      this.allTabsExpanded = !this.allTabsExpanded

      Object.entries(this.$refs)
        .filter(entry => entry[0].startsWith('accordion'))
        .forEach(entry => {
          const tab = entry[1]
          if (!tab) return
          tab.show = this.allTabsExpanded
        })
    }
  }
}
