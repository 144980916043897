<template>
  <div class="detail-form printable">

    <GlobalEvents
      v-if="listNavigation"
      :filter="filterGlobalEvents"
      @keyup.left="prevItem"
      @keyup.up="prevItem"
      @keyup.right="nextItem"
      @keyup.down="nextItem"
      @keyup.esc="cancel"
      @keyup.prevent.ctrl.s="save()"
    />

    <h6>
      <div class="detail-header" style="display: flex">

        <b-button pill size="sm" variant="light" class="list-nav-button return-to-list not-printable" @click="$emit('return-to-list')">
          <font-awesome-icon-with-title icon="arrow-left" title="Back to List" />
        </b-button>

        <template v-if="addingNew">
          <div class="header-title">
            Add New {{ resourceName }}
          </div>
        </template>
        <template v-else>
          <div class="header-title">
            <slot name="header-edit-title" :detailData="originalData" :cancel="cancel">
              Edit {{ resourceName }}
            </slot>
          </div>
          <id-icon :id="originalData.id" />
          <audit-icon
            v-if="showAudit"
            :instanceId="originalData.id"
            :kind="auditResourceName"
            :resourceName="resourceName"
          />
          <font-awesome-icon-with-title icon="print" title="Print" @click="print" class="print-button not-printable" />
          <div v-if="listNavigation" class="detail-header-paging">
            {{ listNavigation.index + 1 }} of {{ listNavigation.length }}
            <font-awesome-icon-with-title
              icon="circle-chevron-left" size="lg"
              title="Previous"
              class="list-nav-button not-printable" style="margin-left: .75rem"
              :class="{ disabled: listNavigation.index < 1 }"
              @click="prevItem" />
            <font-awesome-icon-with-title
              icon="circle-chevron-right" size="lg"
              title="Next"
              class="list-nav-button not-printable" style="margin-left: .5rem"
              :class="{ disabled: listNavigation.index >= listNavigation.length - 1 }"
              @click="nextItem" />
          </div>
        </template>
      </div>
    </h6>

    <hr/>

    <slot v-bind="formSlotProps"></slot>

    <slot name="footer">
      <div class="not-printable">
        <hr/>

        <b-form-invalid-feedback class="summary-form-invalid-feedback"
          :force-show="formInvalid"
        >
          ** Fix errors above before saving.
        </b-form-invalid-feedback>
        <!-- Using <form-summary /> requires vuelidate-error-extractor support nested error message objects (i.e., per field)  -->
        <!-- <form-summary :validator="$v.form" :attributes="fieldLabels" :messages="errorMessages" /> -->

        <div class="footer-buttons">
          <b-button
            v-if="saveEnabled"
            @click="save"
            :disabled="saveDisabled"
            variant="primary"
            class="action save"
            size="sm"
          >
            <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
            Save
          </b-button>

          <b-button
            v-if="saveEnabled"
            @click="save('close')"
            :disabled="saveDisabled"
            variant="primary"
            class="action save"
            size="sm"
          >
            <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
            Save &amp; Close
          </b-button>

          <b-button
            @click="cancel"
            :disabled="cancelDisabled"
            variant="secondary"
            class="action cancel"
            size="sm"
          >
            <font-awesome-icon icon="circle-xmark" />
            {{ formDirty ? 'Cancel' : 'Close' }}
          </b-button>

          <b-button
            v-if="isDeleteEnabled && !addingNew"
            @click="$emit('delete', originalData.id)"
            :disabled="deleteDisabled"
            variant="danger"
            class="action delete"
            size="sm"
          >
            <font-awesome-icon icon="trash-can" />
            Delete
          </b-button>
        </div>

        <slot name="after-footer" v-bind="formSlotProps" />
      </div>

      <denorm-prompt
        ref="denormPrompt"
        :fields="updatedDenormFields"
        @save="saveDenorm"
        @cancel="cancelDenorm"
      />
    </slot>
  </div>
</template>

<script>
import GlobalEvents from 'vue-global-events'
import AuditIcon from '@/components/AuditIcon'
import DenormPrompt from '@/components/DenormPrompt'
import FontAwesomeIconWithTitle from '@/components/FontAwesomeIconWithTitle'
import IdIcon from '@/components/IdIcon'
import _ from 'lodash'
import { mapMutations, mapState } from 'vuex'
import { printWindow } from '@/utils/print'

export default {
  components: {
    AuditIcon,
    DenormPrompt,
    FontAwesomeIconWithTitle,
    GlobalEvents,
    IdIcon
  },
  data () {
    return {
      height: undefined,
      saveOptions: null
    }
  },
  props: {
    orchestrator: {
      type: String,
      required: true
    },
    listNavigation: Object,
    saving: Boolean,
    deleting: Boolean,
    saveEnabled: {
      type: Boolean,
      default: true
    },
    deleteEnabled: [Boolean, Function],
    denormEnabled: {
      type: [Boolean, Function],
      default: true
    }
  },
  computed: {
    resourceName () {
      return this.$store.state[this.orchestrator].resourceName
    },
    originalData () {
      return this.$store.state[this.orchestrator].originalData
    },
    formData () {
      return this.$store.state[this.orchestrator].formData
    },
    formInvalid () {
      return this.$store.state[this.orchestrator].formInvalid
    },
    readOnly () {
      return this.$store.state[this.orchestrator].readOnly
    },
    denormFields () {
      return this.$store.state[this.orchestrator].denormFields
    },
    auditResourceName () {
      return this.$store.state[this.orchestrator].auditResourceName
    },
    supplementalData () {
      return this.$store.state[this.orchestrator].supplementalData
    },
    saveDisabled () {
      return (!this.addingNew && !this.formDirty) || this.formInvalid || this.readOnly || this.saving || this.deleting
    },
    cancelDisabled () {
      return this.saving || this.deleting
    },
    deleteDisabled () {
      return this.saving || this.deleting
    },
    formDirty () {
      return this.$store.getters[`${this.orchestrator}/formDirty`]
    },
    addingNew () {
      return this.$store.getters[`${this.orchestrator}/addingNew`]
    },
    updatedDenormFields () {
      return Object.entries(this.denormFields)
        .filter(entry => {
          return !_.isEqual(this.originalData[entry[0]], this.formData[entry[0]]) &&
            // null/undefined/'' all the same
            !!(this.originalData[entry[0]] || this.formData[entry[0]])
        })
        .map(entry => entry[1])
    },
    needToDenorm () {
      const denormEnabled = _.isFunction(this.denormEnabled) ? this.denormEnabled(this.formData) : this.denormEnabled
      return denormEnabled && !!this.originalData.id && this.updatedDenormFields.length > 0
    },
    canViewAudit () {
      return this.$store.getters.canViewAudit
    },
    showAudit () {
      return Boolean(this.auditResourceName && !this.addingNew && this.canViewAudit)
    },
    formSlotProps () {
      return {
        originalData: this.originalData,
        formData: this.formData,
        formDirty: this.formDirty,
        supplementalData: this.supplementalData,
        eventBus: this.$store.getters[`${this.orchestrator}/eventBus`],
        addingNew: this.addingNew,
        saving: this.saving,
        applyGridTransaction: this.applyGridTransaction,
        exit: this.cancel,
        detailUpdated: this.detailUpdated,
        detailIndex: this.$store.state[this.orchestrator].detailIndex,
        ...mapMutations(this.orchestrator, ['formDataChanged', 'formInvalidChanged'])
      }
    },
    isDeleteEnabled () {
      if (typeof(this.deleteEnabled) === 'function') {
        // TODO: Might be useful to pass all formSlotProps to deleteEnabled function.
        return this.deleteEnabled(this.originalData)
      }
      return !!this.deleteEnabled
    }
  },
  methods: {
    nextItem () {
      this.$emit('nextItem', this.originalData && this.originalData.id)
    },
    prevItem () {
      this.$emit('prevItem', this.originalData && this.originalData.id)
    },
    save (afterSaveAction) {
      if (this.saveDisabled || !this.saveEnabled) return

      if (this.formInvalid) {
        // likely the beforeRouteChange hook called this method.
        // but form not valid, so ignore.
        this.$emit('abortSave')
        return
      }

      if (this.readOnly) {
        this.$snotify.error('You are not allowed to save these changes')
        this.$emit('abortSave')
        return
      }

      if (this.needToDenorm) {
        this.saveOptions = { afterSaveAction }
        this.$refs.denormPrompt.show()
      } else {
        this.$emit('save', this.formData, { afterSaveAction })
      }

      return true
    },
    cancel () {
      if (this.cancelDisabled) return
      this.$emit('cancel')
    },
    saveDenorm (shouldDenorm) {
      this.$emit('save', this.formData, { shouldDenorm, ...this.saveOptions })
    },
    cancelDenorm () {
      this.$emit('abortSave')
    },
    filterGlobalEvents (event, handler, eventName) {
      // console.log('filterGlobalEvents', event)
      // We don't want the arrow/escape buttons to navigate when an input is in focus.
      return event.target.nodeName === 'BODY'
    },
    print () {
      printWindow(window)
    },
    applyGridTransaction (params) {
      this.$emit('apply-grid-transaction', params)
    },
    detailUpdated (data, supplementalData, detailIndex) {
      this.$store.commit(`${this.orchestrator}/detailUpdated`, { data, detailIndex })
    }
  }
}
</script>
<style lang="scss" scoped>

.detail-header {
  color: #444;
  align-items: center;

  .return-to-list {
    margin: auto 2rem auto 1rem;

    ::v-deep svg {
      margin-right: 0px;
    }
  }

  .header-title {
    margin-right: 1rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .detail-header-paging {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 1rem;
    display: flex;
    white-space: nowrap;
  }
}

.audit-icon {
  margin-left: .5rem;
}

.print-button {
  margin: 2px .5rem 0 .75rem;
  cursor: pointer;
}

.list-nav-button {
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.detail-form {
  position: absolute;
  // Set top 3.5rem to offset tab bar fixed position.
  // TODO: This is brittle. Perhaps we should have
  // TODO: specific css for detail-form inside tab.
  top: 3.5rem;
  left: 0px;
  width: 100%;
  height: 100%;

  .footer-buttons {
    margin-top: 15px;
    display: flex;

    .action {
      margin-right: 10px;
      margin-bottom: 15px;

      &.delete {
        margin-left: auto;
      }
    }
  }

  ::v-deep .audit-links {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
<style lang="scss">
@import '~scss/app/print';
</style>
