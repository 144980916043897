<template>
  <ag-grid-vue
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :rowData="allColumnOptions"
    :suppressScrollOnNewData="true"
    :class="{ disabled }"
    @grid-ready="onGridReady"
    @selection-changed="onSelectionChanged"
  >
  </ag-grid-vue>
</template>
<script>
import AgGridVue from '@/components/grid/ag-grid-vue'
import _ from 'lodash'

const ResetToDefaultsHeaderButton = {
  template: `
    <b-button
      variant="link"
      size="sm"
      style="margin-left: -10px; font-size: 12px"
      :disabled="params.parent.disabled"
      @click="params.parent.resetToDefaults()"
    >Reset to Defaults</b-button>
  `
}

export default {
  components: {
    AgGridVue,
    ResetToDefaultsHeaderButton
  },
  props: {
    allColumns: {
      type: Array,
      required: true
    },
    // The following props are defaulted to support Sync Fusion grid columns,
    // like how it's used by AbstractReport.
    // It can be overridden, such as for ag-grid.
    columnFieldKey: {
      type: String,
      default: 'field'
    },
    columnLabelKey: {
      type: String,
      default: 'headerText'
    },
    columnIsSelected: {
      type: Function,
      default: columnData => columnData.visible
    },
    extraColumnAttributes: {
      type: Array,
      default: () => ['width']
    },
    disabled: Boolean
  },
  data () {
    return {
      gridOptions: {
        rowSelection: 'multiple',
        rowDragManaged: true,
        suppressRowClickSelection: true,
        suppressCellFocus: true,
        suppressRowHoverHighlight: true,
        suppressDragLeaveHidesColumns: true,
        // Source grid column visibility is managed by row selection on this column menu grid.
        // There is two-way binding between the allColumns prop and the selectedColumnsUpdated event.
        // Because row selection depends on the cell renderer, if a row isn't rendered, then even
        // though it may be visible in allColumns prop, it will end up getting deselected anyway
        // once the selectedColumnsUpdated event is emitted. To prevent this from happening, we
        // need to turn off virtualization. We do that by increasing rowBuffer to a higher value
        // then we ever expect to see.
        rowBuffer: 200
      },
      columnDefs: [
        {
          suppressMovable: true,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          width: 30
        },
        {
          suppressMovable: true,
          rowDrag: true,
          width: 30
        },
        {
          suppressMovable: true,
          field: 'name',
          headerName: '',
          headerComponent: 'ResetToDefaultsHeaderButton',
          headerComponentParams: { parent: this },
          cellRenderer: params => {
            // use this callback to set row selection state
            params.node.setSelected(params.data.selected)
            return params.value
          }
        }
      ],
      // When allColumns prop changes, the cellRenderer above sets selection state on each column,
      // which in turn triggers the onSelectionChanged with 0 selected nodes on initial render.
      // This causes a racing condition with the parent component restoring saved state that can
      // cause that saved state to be lost. To resolve this racing condition, we'll use this
      // columnResetFlag to track allColumns changes, in order to silence the selectedColumnsUpdated
      // event until it appears that this initial rendering is done. The heuristic we'll use
      // to assume rendering is complete is to check for the first time that there is at least
      // one selected node. It seems to work empirically.
      columnResetFlag: false
    }
  },
  computed: {
    allColumnOptions () {
      return this.allColumns.map(col => ({
        name: col[this.columnLabelKey],
        field: col[this.columnFieldKey],
        selected: this.columnIsSelected(col)
      }))
    },
    allColumnsByField () {
      return Object.fromEntries(this.allColumns.map(col => [col[this.columnFieldKey], col]))
    }
  },
  beforeMount () {
    Object.assign(this.gridOptions, {
      context: {
        componentParent: this
      },
      onRowDragEnd: this.onRowDragEnd
    })
  },
  watch: {
    allColumns (allColumns) {
      this.columnResetFlag = true
    }
  },
  methods: {
    onGridReady (params) {
      this.gridOptions.api = params.api
    },
    onSelectionChanged (event) {
      if (this.columnResetFlag && event.api.getSelectedNodes().length > 0) {
        this.columnResetFlag = false
      }

      if (!this.columnResetFlag) {
        this.emitSelectedRows(event.api)
      }
    },

    onRowDragEnd (event) {
      this.emitSelectedRows(event.api)
    },

    emitSelectedRows (gridApi) {
      const selectedColumns = _.sortBy(gridApi.getSelectedNodes(), node => node.rowIndex)
        .map(node => {
          const field = node.data.field
          const col = this.allColumnsByField[field]
          // TODO: I
          return Object.assign(
            { [this.columnFieldKey]: field },
            Object.fromEntries(this.extraColumnAttributes.map(attr => [attr, col[attr]]))
          )
        })
      this.$emit('selectedColumnsUpdated', selectedColumns)
    },

    resetToDefaults () {
      this.$emit('reset-to-defaults')
    }
  }
}
</script>
<style lang="scss" scoped>

$background-color: white;

.ag-grid ::v-deep {
  width: 200px;
  height: 300px;

  .ag-header-container {
    background-color: $background-color;
  }

  .ag-body {
    background-color: $background-color;
  }

  .ag-header-cell {
    border-right: none;

    &::after {
      visibility: hidden;
    }
  }

  .ag-row {
    &.ag-row-even, &.ag-row-odd {
      background-color: $background-color;
    }
  }
}

.ag-grid.disabled ::v-deep {
  .ag-checkbox, .ag-drag-handle {
    pointer-events: none;
    opacity: .5;
  }
}

</style>
