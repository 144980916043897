
export default {
  name: 'manage-punches',
  label: 'Raw Punches',
  path: 'punches',
  link: 'punches',
  props: true,
  icon: 'business-time',
  description: 'View and edit employee punches.',
  component: () => import('@/components/TabPanel'),
  requireGetter: 'canAccessManagePunches',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'punch-list',
      label: 'List',
      title: 'Raw Punches',
      path: 'list/:view?',
      component: () => import(/* webpackChunkName: "punches-manage" */ './PunchList')
    },
    {
      name: 'missed-punch-list',
      label: 'Missed',
      title: 'Missed Punches',
      path: 'missed-punches/:view?',
      component: () => import(/* webpackChunkName: "missed-punches-manage" */ './missedpunch/MissedPunchList'),
      require: state => state.enableSubmitMissedPunch
    },
    {
      path: '',
      redirect: { name: 'punch-list' }
    }
  ]
}
