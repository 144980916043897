
export default {
  name: 'billing',
  label: 'Billing',
  link: 'billing',
  path: 'billing',
  props: true,
  icon: 'credit-card',
  description: 'View and edit your billing and/or subscription information.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'manage_subscription',
  requireActive: false,
  requireGetter: 'notInApp',
  require: (state, getters) => !getters['userProfile/isWhiteLabel'],
  breadcrumbLeaf: true,
  children: [
    {
      name: 'billing-subscription',
      label: 'Subscription',
      title: 'Billing Subscription',
      path: 'subscription/:view?',
      requireActive: false,
      component: () => import(/* webpackChunkName: "billing" */ './Subscription')
    },
    {
      name: 'billing-invoices',
      label: 'Invoices',
      title: 'Billing Invoices',
      path: 'invoices',
      requireActive: false,
      component: () => import(/* webpackChunkName: "billing" */ './Invoices'),
      require: (state, getters) => !!state.billingCustomerId && !getters.isFreeTrial
    },
    {
      path: '',
      redirect: { name: 'billing-subscription' }
    }
  ]
}
