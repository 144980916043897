<template>
  <b-modal
    ref="modal"
    title="PDF Export Preferences"
    :ok-title="okTitle"
    hide-header-close
    no-close-on-backdrop
    centered
    @ok="ok"
  >
    <b-form-group label="Layout" class="layout">
      <b-radio-group v-model="pageOrientation">
        <b-radio value="Portrait">
          Portrait
          <font-awesome-icon icon="file-image" />
        </b-radio>
        <b-radio value="Landscape">
          Landscape
          <font-awesome-icon icon="image" />
        </b-radio>
      </b-radio-group>
    </b-form-group>
    <b-form-group v-if="showFitWidthToPage" label="Fit width to page" label-cols="3">
      <b-form-checkbox v-model="fitWidthToPage" />
    </b-form-group>
    <b-form-group label="Include titles" label-cols="3">
      <b-form-checkbox v-model="includeTitles" />
    </b-form-group>
  </b-modal>
</template>
<script>
export default {
  name: 'PdfExportModal',
  props: {
    okTitle: {
      type: String,
      default: 'Export'
    },
    // The following value is only used for initial value when modal is mounted.
    value: Object,
    showFitWidthToPage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      pageOrientation: 'Portrait',
      includeTitles: true,
      fitWidthToPage: true
    }
  },
  mounted () {
    if (this.value) Object.assign(this, this.value)
    this.isMounted = false
  },
  methods: {
    ok () {
      this.$emit('ok', {
        pageOrientation: this.pageOrientation,
        includeTitles: this.includeTitles,
        fitWidthToPage: this.showFitWidthToPage ? this.fitWidthToPage : undefined
      })
    },
    show () {
      if (!this.isMounted) {
        this.$mount()
        document.body.appendChild(this.$el)
        this.$refs.modal.show()
        this.isMounted = true
      }

      this.$refs.modal.show()
    },
    onHidden () {
      if (this.$el) this.$el.remove()
      this.isMounted = false
      this.$destroy()
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.25rem;

  .custom-checkbox {
    position: relative;
    top: 7px;
  }

  &.layout {
    margin-bottom: .5rem;
    svg {
      margin-left: .5rem;
      font-size: 1.2rem;
    }
  }
}
</style>
