
export default {
  name: 'manage-timecards',
  label: 'Time Cards',
  path: 'timecards',
  link: 'timecards',
  props: true,
  icon: 'table',
  description: 'View and edit worker time cards.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'edit_punches',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timecard-editor',
      label: 'Single Edit',
      title: 'Edit Timecards',
      path: 'single-edit/:view?',
      component: () => import(/* webpackChunkName: "timecards-manage" */ './TimeCards')
    },
    {
      name: 'shiftcard-list',
      label: 'Bulk Edit',
      title: 'Bulk Edit Timecards',
      path: 'bulk-edit/:view?',
      requireGetter: 'scheduleEnabled',
      component: () => import(/* webpackChunkName: "timecards-manage" */ './ShiftCardList')
    },
    {
      path: '',
      redirect: { name: 'timecard-editor' }
    }
  ]
}
