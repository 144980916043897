import moment from 'moment'
import inflection from 'inflection'
import _ from 'lodash'

function formatMillisAsClockDuration (millis) {
  const sign = millis < 0 ? '-' : ''
  millis = Math.abs(millis)
  const totalMinutes = Math.round(millis / 60000)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

function formatMillisAsDecimal (millis, decimalPlaces) {
  const negative = millis < 0
  millis = Math.abs(millis)
  const hours = Math.floor(millis / (60 * 60 * 1000))
  const minutes = millis / 60000 % 60
  const duration = _.round(hours + minutes / 60, decimalPlaces)
  const sign = negative && duration ? '-' : ''
  return `${sign}${duration.toFixed(decimalPlaces)}`
}

export function formatIsoDuration (isoDuration) {
  if (!isoDuration) return ''
  const duration = moment.duration(isoDuration)
  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
  const formatted = units.flatMap(unit => {
    const cardinality = duration[unit]()
    return cardinality ? [`${cardinality} ${inflection.inflect(unit, cardinality)}`] : []
  }).join(' ')
  return formatted || ''
}

export default {
  formatMillisAsClockDuration,
  formatMillisAsDecimal
}
