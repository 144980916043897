<template>
  <div class="dashboard">
    <div class="line-break" />
    <current-pay-period v-if="payPeriodVisible" />
    <last-pay-period v-if="payPeriodVisible" />
    <div class="line-break" />
    <face-failure v-if="faceFailureVisible" />
    <employee-usage v-if="permissions.includes('edit_employees')" />
    <no-console-access v-if="noConsoleAccess" />
  </div>
</template>
<script>
import CurrentPayPeriod from './CurrentPayPeriod'
import EmployeeUsage from './EmployeeUsage'
import FaceFailure from './FaceFailure'
import LastPayPeriod from './LastPayPeriod'
import NoConsoleAccess from './NoConsoleAccess'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    CurrentPayPeriod,
    EmployeeUsage,
    FaceFailure,
    LastPayPeriod,
    NoConsoleAccess
  },
  computed: {
    ...mapGetters(['hasFaceFeature', 'noConsoleAccess']),
    ...mapState(['permissions']),
    faceFailureVisible () {
      return this.hasFaceFeature && this.permissions.includes('manage_clock_logs')
    },
    payPeriodVisible () {
      return this.permissions.includes('edit_punches') && this.permissions.includes('view_reports')
    }
  }
}
</script>
<style lang="scss" scoped>

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  > .panel {
    margin-right: 1rem;
    max-width: 30rem; // default max width

    // use lower z-index for dashboard panel masks than default masks,
    // so that sidebar can open over them.
    ::v-deep .v-mask {
      z-index: 1000;

      > .v-mask-contents {
        z-index: 1001;
      }
    }
  }

  .line-break {
    flex-basis: 100%;
  }
}

</style>
