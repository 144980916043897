import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'

// TODO: Move to separate file
registerSingletonResourceWithStore('deviceDefaults', 'clocks/defaults', store)

// Sync changes from device defaults to root store module.
store.watch(
  state => _.pick(state.deviceDefaults.originalData, [
    'geoEnabled', 'bgGeoEnabled', 'nfcTagEnabled', 'qrCodeEnabled', 'enableSubmitMissedPunch'
  ]),
  syncSettings => {
    if (syncSettings.geoEnabled === undefined) {
      // This is a litmus test to determine whether Vue is just loading,
      // or whether something actually changed.
      return
    }
    // Get same keys in root state
    const rootSettings = _.pick(store.state, Object.keys(syncSettings))
    const updated = updatedDiff(rootSettings, syncSettings)
    if (!_.isEmpty(updated)) {
      store.dispatch('updateSession', updated)
    }
  }
)

export default {
  name: 'devices',
  label: 'Devices',
  link: 'devices',
  path: 'devices',
  props: true,
  icon: 'mobile-alt',
  description: 'View and edit your time clock devices and geo rules.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'edit_clocks',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'device-list',
      label: 'List',
      title: 'Devices',
      path: 'list/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "devices" */ './DeviceList')
    },
    {
      name: 'geo-rule-list',
      label: 'Geo Fences',
      path: 'georules/:view?',
      props: true,
      requireFeature: 'geo',
      requireGetter: 'geoEnabled',
      component: () => import(/* webpackChunkName: "devices" */ './GeoRuleList')
    },
    {
      name: 'device-defaults',
      label: 'Defaults',
      title: 'Device Defaults',
      path: 'defaults/:view?',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "devices" */ './DeviceDefaults')
    },
    {
      path: '',
      redirect: { name: 'device-list' }
    }
  ]
}
