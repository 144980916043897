import store from '@/store'
import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'

registerSingletonResourceWithStore('costingDefaults', 'costing/defaults', store)

// Sync changes from costing defaults to root store module.
store.watch(
  state => ({
    costingMode: state.costingDefaults.originalData.costingMode,
    jobRequired: state.costingDefaults.originalData.jobRequired,
    canRestrictJobAccessByUser: state.costingDefaults.originalData.canRestrictJobAccessByUser
  }),
  syncSettings => {
    if (syncSettings.costingMode === undefined) {
      // This is a litmus test to determine whether Vue is just loading,
      // or whether something actually changed.
      return
    }
    // Get same keys in root state
    const rootSettings = _.pick(store.state, Object.keys(syncSettings))
    const updated = updatedDiff(rootSettings, syncSettings)
    if (!_.isEmpty(updated)) {
      store.dispatch('updateSession', updated)
    }
  }
)

registerListResourceWithStore('jobCategories', 'costing/jobcategories', store)

export default {
  name: 'costing',
  label: 'Costing',
  link: 'costing',
  path: 'costing',
  props: true,
  icon: 'industry',
  description: 'View and edit your costing settings, including jobs.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'edit_jobs',
  requireFeature: 'job_costing',
  require: (state, getters) => {
    // If costing is not enabled, then only allow costing route if user has
    // permission to enable costing.
    return state.permissions.includes('edit_settings') || getters.costingEnabled
  },
  breadcrumbLeaf: true,
  children: [
    {
      name: 'costing-defaults',
      label: 'Defaults',
      title: 'Costing Defaults',
      path: 'defaults',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "costing" */ './CostingDefaultsForm')
    },
    {
      name: 'job-list',
      label: 'Jobs',
      path: 'jobs/:view?',
      requireGetter: 'costingEnabled',
      component: () => import(/* webpackChunkName: "costing" */ './JobList')
    },
    {
      name: 'costing-jobcategories',
      label: 'Job Categories',
      path: 'jobCategories',
      requireGetter: 'jobCategoryEnabled',
      component: () => import(/* webpackChunkName: "costing" */ './JobCategories'),
      nav: false
    },
    {
      name: 'job-phase-list',
      label: 'Job Phases',
      path: 'job-phases/:view?',
      requireGetter: 'enhancedCostingEnabled',
      component: () => import(/* webpackChunkName: "costing-jobphaselist" */ './JobPhaseList')
    },
    {
      name: 'cost-code-list',
      label: 'Cost Codes',
      path: 'cost-codes/:view?',
      requireGetter: 'enhancedCostingEnabled',
      component: () => import(/* webpackChunkName: "costing-costcodelist" */ './CostCodeList')
    },
    {
      path: '',
      redirect: to => {
        return { name: store.state.permissions.includes('edit_settings') ? 'costing-defaults' : 'job-list' }
      }
    }
  ]
}
