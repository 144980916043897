export default {
  name: 'manage-payroll',
  label: 'Payroll',
  link: 'payroll',
  path: 'payroll',
  props: true,
  icon: 'money-check-dollar',
  description: 'View and generate pay runs.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'manage_payroll',
  requireFeature: 'pay_run',
  requireGetter: 'payRunEnabled',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'pay-run-list',
      label: 'Pay Runs',
      path: 'runs/:view?',
      component: () => import(/* webpackChunkName: "payroll-manage" */ './PayRunList'),
      children: [
        {
          name: 'pay-summary-detail',
          path: 'summaries/:paySummaryId',
          component: () => import(/* webpackChunkName: "payroll-manage" */ './PaySummaryDetail'),
        }
      ]
    },
    {
      name: 'net-pay-adjustment-list',
      label: 'Adjustments',
      path: 'netpay/adjustments/:view?',
      requireGetter: 'netPayEnabled',
      component: () => import(/* webpackChunkName: "netpay-manage" */ './NetPayAdjustmentList')
    },
    {
      path: '',
      redirect: { name: 'pay-run-list' }
    }
  ]
}
