import _ from 'lodash'

function width () {
  return window.innerWidth
}

function height () {
  return window.innerHeight
}

function initSizeMonitor (store) {
  const state = {
    clientWidth: width(),
    clientHeight: height()
  }

  store.registerModule('size', {
    namespaced: true,
    state () {
      return state
    }
  })

  window.addEventListener('resize', _.debounce(() => {
    state.clientWidth = width()
    state.clientHeight = height()
  }), 50)
}

export {
  initSizeMonitor
}
