<template>
  <span ref="icon" class="icon-container" :class="$attrs['class']">
    <font-awesome-icon
      v-bind="$attrs"
      v-on="$listeners"
    />
    <b-popover v-if="title" :target="() => $refs.icon" triggers="hover" custom-class="not-printable">
      <template slot="title"></template>
      {{ title }}
    </b-popover>
  </span>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'FontAwesomeIconWithTitle',
  inheritAttrs: false,
  props: ['title'],
  computed: {
    forwardAttributes () {
      return _.omit(this.$attrs, ['class'])
    }
  }
}
</script>
