import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'

registerSingletonResourceWithStore('disciplineSettings', 'discipline/settings', store)

registerListResourceWithStore('customInfractionTypes', 'discipline/custom-infraction-types', store)
registerListResourceWithStore('disciplineEventTypes', 'discipline/event-types', store)
registerListResourceWithStore('disciplinePolicies', 'discipline/policies', store)

store.watch(
  state => ({
    disciplineEnabled: state.disciplineSettings.originalData.enabled
  }),
  syncSettings => {
    if (syncSettings.disciplineEnabled === undefined) {
      // This is a litmus test to determine whether Vue is just loading,
      // or whether something actually changed.
      return
    }
    // Get same keys in root state
    const rootSettings = _.pick(store.state, Object.keys(syncSettings))
    const updated = updatedDiff(rootSettings, syncSettings)
    if (!_.isEmpty(updated)) {
      store.dispatch('updateSession', updated)
    }
  }
)

export default {
  name: 'discipline-settings',
  label: 'Attendance',
  link: 'attendance',
  path: 'attendance',
  props: true,
  icon: 'hundred-points',
  description: 'View and edit attendance settings',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'edit_settings',
  requireFeature: 'discipline',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'discipline-defaults',
      label: 'Defaults',
      title: 'Attendance Defaults',
      path: 'defaults',
      component: () => import(/* webpackChunkName: "discipline-defaults" */ './DisciplineDefaults')
    },
    {
      name: 'discipline-policies',
      label: 'Policies',
      title: 'Attendance Policies',
      path: 'policies/:view?',
      props: true,
      requireGetter: 'disciplineEnabled',
      component: () => import(/* webpackChunkName: "discipline-policies" */ './DisciplinePolicyList')
    },
    {
      name: 'custom-infraction-types',
      label: 'Custom Infraction Types',
      title: 'Custom Infraction Types',
      path: 'custom-infraction-types',
      requireGetter: 'disciplineEnabled',
      component: () => import(/* webpackChunkName: "custom-infraction-types" */ './CustomInfractionTypeList')
    },
    {
      name: 'discipline-event-types',
      label: 'Event Types',
      title: 'Event Types',
      path: 'event-types',
      requireGetter: 'disciplineEnabled',
      component: () => import(/* webpackChunkName: "discipline_event-types" */ './DisciplineEventTypeList')
    },
    {
      path: '',
      redirect: { name: 'discipline-defaults' }
    }
  ]
}
