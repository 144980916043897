<template>
  <pay-period title="Current Pay Period"
    whichPeriod="thisperiod"
    showClockLogFailures
    barBackgroundColor="#99cf99"
    barBorderColor="#008800"
  />
</template>
<script>
import PayPeriod from './PayPeriod'

export default {
  name: 'CurrentPayPeriod',
  components: {
    PayPeriod
  },
  data () {
    return {
      // Lower the refresh interval for this pane,
      // because it's expensive to compute.
      refreshInterval: 60 * 60 * 1000 // 1 hour
    }
  }
}
</script>
