
export default {
  name: 'manage-clock-logs',
  label: 'Clock Logs',
  path: 'clocklogs',
  link: 'clocklogs',
  props: true,
  icon: 'history',
  description: 'View and manage clock logs.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'manage_clock_logs',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'clock-log-list',
      label: 'List',
      title: 'Clock Logs',
      path: 'list/:view?',
      component: () => import(/* webpackChunkName: "clocklogs-manage" */ './ClockLogList')
    },
    {
      path: '',
      redirect: { name: 'clock-log-list' }
    }
  ]
}
