<i18n>
{
  "en-US": {
    "splash-text": "Loading Application"
  }
}
</i18n>

<template>
  <Spinner height="100vh">
    <div slot="header" class="splash-icon"></div>
    <div slot="footer" class="splash-text">{{ $t('splash-text') }}</div>
  </Spinner>
</template>

<script>
import Spinner from './Spinner'

export default {
  components: {
    Spinner
  }
}
</script>

<style lang="scss">

.spinner-container {
    .splash-icon {
      background-image: url("../../public/img/logo-symbol.png");
      background-repeat: no-repeat;
      background-size: 57px;
      width: 57px;
      height: 57px;
      margin-bottom: 50px;
    }

    .splash-text {
      margin-top: 10px;
      font-size: 13px;
    }
}
</style>
