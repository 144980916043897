import { render, staticRenderFns } from "./FaceFailureContent.vue?vue&type=template&id=336b0847&scoped=true"
import script from "./FaceFailureContent.vue?vue&type=script&lang=js"
export * from "./FaceFailureContent.vue?vue&type=script&lang=js"
import style0 from "./FaceFailureContent.vue?vue&type=style&index=0&id=336b0847&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336b0847",
  null
  
)

export default component.exports