export default {
  data () {
    return {
      activeRoute: null
    }
  },
  watch: {
    '$route': {
      handler (to, from) {
        if (this.$_routeIsActive) {
          this.activeRoute = this.$route
        }
      },
      immediate: true
    }
  },
  beforeCreate () {
    // assume it gets created only when route is active
    this.$_routeIsActive = true
  },
  activated () {
    this.$_routeIsActive = true
    this.activeRoute = this.$route
  },
  deactivated () {
    this.$_routeIsActive = false
    this.activeRoute = null
  }
}
