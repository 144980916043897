import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'

registerListResourceWithStore('teamSchedules', 'schedule/teams', store)

export default {
  name: 'manage-schedule',
  label: 'Schedule',
  path: 'schedule',
  link: 'schedule',
  props: true,
  icon: 'calendar-alt',
  description: 'View and edit shift schedules.',
  component: () => import('@/components/TabPanel'),
  requirePerm: 'manage_schedule',
  requireGetter: 'scheduleEnabled',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'schedules',
      label: 'Schedules',
      path: 'schedules',
      component: () => import(/* webpackChunkName: "schedules" */ './Schedules')
    },
    {
      name: 'schedule-teams',
      label: 'Teams',
      title: 'Schedule Teams',
      path: 'teams',
      component: () => import(/* webpackChunkName: "shifts" */ './ScheduleTeamList')
    },
    {
      path: '',
      redirect: { name: 'schedules' }
    }
  ]
}
