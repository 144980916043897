<template>
  <hover-icon class="help-text-icon" icon="circle-question">
    <slot>{{ helpText }}</slot>
  </hover-icon>
</template>
<script>
import HoverIcon from './HoverIcon'

export default {
  name: 'HelpTextIcon',
  components: {
    HoverIcon
  },
  props: {
    helpText: {
      type: String,
      default: ''
    }
  }
}
</script>
