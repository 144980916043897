<template>
  <img
    :src="url"
    class="avatar custom"
    :style="[$attrs.style || {}, { width: size, height: size }]"
    v-img-fallback="imgFallback"
    v-if="url"
  />
  <font-awesome-icon
    icon="circle-user"
    class="avatar generic"
    :style="{ fontSize: size }"
    v-else />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    url: String,
    size: {
      type: String,
      default: '3rem'
    }
  },
  data () {
    return {
      imgFallback: {
        loading: '/img/fa-user-circle.png',
        error: '/img/fa-user-circle.png'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  vertical-align: middle;

  &.custom {
    border-radius: 50%;
  }

  &.generic {
    color: #aaa;
  }

  ::v-deep .img {
    border-radius: 50%;
  }
}
</style>
