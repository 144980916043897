
import moment from 'moment-timezone'
import _ from 'lodash'
import inflection from 'inflection'

// sort of arbitrary, but perhaps good choice because Jan-1-2006 is a Sunday
const biWeekEpochString = '2006-01-01'

export function biWeekFixedPoint (today, timezone) {
  // calculate first day of current fixed biweek
  const biWeekEpoch = moment.tz(biWeekEpochString, timezone)
  const daysElapsed = today.diff(biWeekEpoch, 'days')
  const todayBiWeekOffset = daysElapsed % 14
  const fixedPoint = moment(today).subtract(todayBiWeekOffset, 'days').startOf('day')
  return fixedPoint
}

function getBiWeeklyDateRange (timezone, biWeekStarts, whichPeriod, today) {
  if (biWeekStarts < 1 || biWeekStarts > 14) {
    // must be in range [1,14]
    biWeekStarts = 1
  }

  const currentBiWeekFixedPoint = biWeekFixedPoint(today, timezone)

  const startOfCurrentPeriod = _.flow(
    m => m.add(biWeekStarts - 1, 'days'),
    m => m.subtract(m.isAfter(today) ? 14 : 0, 'days')
  )(moment(currentBiWeekFixedPoint))

  const startOfPeriod = (() => {
    switch (whichPeriod) {
      case 'lastperiod':
        return moment(startOfCurrentPeriod).subtract(14, 'days')
      case 'nextperiod':
        return moment(startOfCurrentPeriod).add(14, 'days')
      case 'thisperiod':
      default:
        return startOfCurrentPeriod
    }
  })()

  return [startOfPeriod, moment(startOfPeriod).add(14, 'days')]
}

// From the moment api docs:
// http://momentjs.com/docs/#/get-set/date/
// it sounds like if the specified day of month exceeds the number of days in that
// month, then the date is supposed to latch onto the end of the month.
// But instead, it overflows into the next month. So, adjustMonth() works
// around that.
function adjustMonth (today, monthOffset, dayOfMonth) {
  return _.flow(
    m => m.add(monthOffset, 'months'),
    m => m.date(Math.min(dayOfMonth, m.daysInMonth()))
  )(moment(today))
}

function getMonthlyDateRange (timezone, monthStarts, whichPeriod, today) {
  if (monthStarts < 1 || monthStarts > 31) {
    // must be in range [1,31]
    monthStarts = 1
  }

  // when we add or subtract months below, always set date afterward to deal with differences in last day of month
  const dateIsBeforeMonthStarts = today.date() < monthStarts && today.date() < today.daysInMonth()

  const startOfCurrentPeriod = adjustMonth(today, dateIsBeforeMonthStarts ? -1 : 0, monthStarts)
  const startOfPeriod = (() => {
    switch (whichPeriod) {
      case 'lastperiod':
        return adjustMonth(startOfCurrentPeriod, -1, monthStarts)
      case 'nextperiod':
        return adjustMonth(startOfCurrentPeriod, 1, monthStarts)
      case 'thisperiod':
      default:
        return startOfCurrentPeriod
    }
  })()

  return [startOfPeriod, adjustMonth(startOfPeriod, 1, monthStarts)]
}

function getSemiMonthlyDateRange (timezone, semiMonthStarts, whichPeriod, today) {
  if (semiMonthStarts < 1 || semiMonthStarts > 13) {
    // must be in range [1,13]
    semiMonthStarts = 1
  }

  const semiMonthSecondStarts = semiMonthStarts + 15

  const startOfCurrentPeriod = today.date() < semiMonthStarts
    ? moment(today).month(today.month() - 1).date(semiMonthSecondStarts)
    : (today.date() < semiMonthSecondStarts
      ? moment(today).date(semiMonthStarts)
      : moment(today).date(semiMonthSecondStarts))

  const startOfPeriod = (() => {
    switch (whichPeriod) {
      case 'lastperiod':
        return startOfCurrentPeriod.date() === semiMonthStarts
          ? moment(startOfCurrentPeriod).month(startOfCurrentPeriod.month() - 1).date(semiMonthSecondStarts)
          : moment(startOfCurrentPeriod).date(semiMonthStarts)
      case 'nextperiod':
        return startOfCurrentPeriod.date() === semiMonthStarts
          ? moment(startOfCurrentPeriod).date(semiMonthSecondStarts)
          : moment(startOfCurrentPeriod).date(semiMonthStarts).month(startOfCurrentPeriod.month() + 1)
      case 'thisperiod':
      default:
        return startOfCurrentPeriod
    }
  })()

  const endOfPeriod = startOfPeriod.date() === semiMonthStarts
    ? moment(startOfPeriod).date(semiMonthSecondStarts)
    : moment(startOfPeriod).month(startOfPeriod.month() + 1).date(semiMonthStarts)

  return [startOfPeriod, endOfPeriod]
}

function getWeeklyDateRange (timezone, weekStarts, whichPeriod, today) {
  if (weekStarts < 1 || weekStarts > 7) {
    // must be in range [1,7]
    weekStarts = 1
  }

  // convert to 0-based value
  // moment.js represents Sunday as 0 and Saturday as 6
  weekStarts -= 1

  const startOfCurrentPeriod = moment(today).day(weekStarts - (today.day() < weekStarts ? 7 : 0))

  const startOfPeriod = (() => {
    switch (whichPeriod) {
      case 'lastperiod':
        return moment(startOfCurrentPeriod).subtract(7, 'days')
      case 'nextperiod':
        return moment(startOfCurrentPeriod).add(7, 'days')
      case 'thisperiod':
      default:
        return startOfCurrentPeriod
    }
  })()

  return [startOfPeriod, moment(startOfPeriod).add(7, 'days')]
}

// end of date range is always exclusive, i.e., midnight after last day of period
export function getPayPeriodDateRange (timezone, periodType, periodStarts, whichPeriod = 'thisperiod', refDate) {

  const today = moment.tz(refDate || undefined, timezone).startOf('day')

  switch (periodType) {
    case 'biweek':
      return getBiWeeklyDateRange(timezone, periodStarts, whichPeriod, today)
    case 'month':
      return getMonthlyDateRange(timezone, periodStarts, whichPeriod, today)
    case 'semimonth':
      return getSemiMonthlyDateRange(timezone, periodStarts, whichPeriod, today)
    case 'week':
    default:
      return getWeeklyDateRange(timezone, periodStarts, whichPeriod, today)
  }
}

export function getPickerDatesForDatetimeRange (startDt, endDt) {
  // It's assumed that startDt/endDt are already localized moment objects,
  // and that endDt is midnight at exclusive end of range.
  return [moment(startDt), moment(endDt).subtract(1, 'day')]
}

export function getPayPeriodDateRangeAsPickerDates (timezone, periodType, periodStarts, whichPeriod, refDate) {
  const range = getPayPeriodDateRange(timezone, periodType, periodStarts, whichPeriod, refDate)
  return getPickerDatesForDatetimeRange(...range)
}

// meaning of pay_period_starts_on depends upon pay_period value:
//   - weekly:      1-7 is Su-Sa (ISO 8601 weekday numbering begins on Monday=1,
//                  but we will begin on Sunday=1 like Javascript Date object)
//   - biweekly:    1-14 is offset from Sun-Jan-1-2006
//   - semimonthly: 1 is 1st & 16th of month
//   - monthly:     1-28 is day of month, and 31 is last day of month

const weekOptions = [
  { value: 1, label: 'Sunday' },
  { value: 2, label: 'Monday' },
  { value: 3, label: 'Tuesday' },
  { value: 4, label: 'Wednesday' },
  { value: 5, label: 'Thursday' },
  { value: 6, label: 'Friday' },
  { value: 7, label: 'Saturday' }
]

const semiMonthOptions = _.range(1, 14).map(i => ({
  value: i,
  label: inflection.ordinalize(`${i} & ${i + 15} of the month`)
}))

const monthOptions = _.range(1, 29).map(i => ({
  value: i,
  label: inflection.ordinalize(`${i} of the month`)
})).concat([{ value: 31, label: 'Last day of the month' }])

function biWeekOptions (timezone, dateFormatter) {
  // dynamic generation based on current day
  const now = moment.tz(timezone)
  const currentBiWeekFixedPoint = biWeekFixedPoint(now, timezone)
  // Add 1 because offset is 1-based.
  const todayVal = now.diff(currentBiWeekFixedPoint, 'days') + 1
  const biWeekValue = d => {
    const delta = todayVal - d
    return delta > 0 ? delta : delta + 14
  }

  return _.range(13, -1).map(i => ({
    value: biWeekValue(i),
    label: dateFormatter(moment(now).subtract(i, 'days'))
  }))
}

export const payPeriodOptions = [
  { value: 'week', label: 'Weekly' },
  { value: 'biweek', label: 'Bi-weekly' },
  { value: 'semimonth', label: 'Semi-monthly' },
  { value: 'month', label: 'Monthly' }
]

export function payPeriodLabel (payPeriod) {
  const option = payPeriodOptions.find(option => option.value === payPeriod)
  return option && option.label
}

export function optionsForPayPeriod (payPeriod, timezone, dateFormatter) {
  if (payPeriod === 'week') return weekOptions
  if (payPeriod === 'biweek') return biWeekOptions(timezone, dateFormatter)
  if (payPeriod === 'semimonth') return semiMonthOptions
  if (payPeriod === 'month') return monthOptions
  return [] // unexpected
}

export function payPeriodStartsLabel (payPeriod, payPeriodStarts, timezone, dateFormatter) {
  const options = optionsForPayPeriod(payPeriod, timezone, dateFormatter)
  const option = options.find(option => option.value === payPeriodStarts)
  return option && option.label
}
