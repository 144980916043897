<template>
  <div>
    <router-view v-if="settingsLoaded || interventionNeeded || fatalError"></router-view>
    <splashscreen v-else />
    <vue-snotify></vue-snotify>
    <component ref='modalComponent' :is="modalComponent" v-bind="modalProps" v-on="modalEvents"></component>
  </div>
</template>

<script>
import Splashscreen from './components/Splashscreen'
import WelcomeMessage from './components/WelcomeMessage'
import { mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'
import constants from '@/constants'
import AutoUpdateService from './services/AutoUpdateService'
import eventBus from '@/EventBus'
import Vue from 'vue'
import reusePromise from 'reuse-promise'
import { initFcm } from '@/services/clients/firebase'

const oConstants = constants()

AutoUpdateService.start()

const importConvertPunchModal = reusePromise(
  () => import(/* webpackChunkName: "convert-punch-modal" */ '@/views/manage/clocklogs/ConvertPunchModal'),
  { memoize: true }
)
const importPunchFormModal = reusePromise(
  () => import(/* webpackChunkName: "punch-form-modal" */ '@/views/manage/punches/PunchFormModal'),
  { memoize: true }
)
const importShiftSummaryModal = reusePromise(
  () => import(/* webpackChunkName: "shift-summary-modal" */ '@/views/manage/timecards/ShiftSummaryModal'),
  { memoize: true }
)
const importTimeOffDateEntriesModal = reusePromise(
  () => import(/* webpackChunkName: "time-off-date-entries-modal" */ '@/views/manage/timeoff/TimeOffDateEntriesModal'),
  { memoize: true }
)
const importFileSaver = reusePromise(
  () => import(/* webpackChunkName: "file-saver" */ 'file-saver'),
  { memoize: true }
)

export default {
  name: 'app',
  computed: {
    ...mapState(['settingsLoaded', 'organizationId', 'organizationName', 'organizationActive', 'fatalError', 'activeWorkers', 'permissions']),
    ...mapGetters(['needsSetup', 'insideOrgNamespace', 'inSignUpWizard']),
    ...mapState('orgService', ['selectedOrg', 'allOrgs']),
    ...mapGetters('orgService', ['userInterventionNeeded']),
    ...mapState({
      jwtServiceInitialized: state => state.jwt.initialized
    }),
    ...mapGetters('jwt', ['email', 'emailVerified', 'displayName', 'providerId']),
    interventionNeeded () {
      return (this.jwtServiceInitialized && !this.emailVerified) || this.userInterventionNeeded
    },
    showWelcomeMessage () {
      return !this.welcomeMessageShown &&
        this.insideOrgNamespace &&
        !this.inSignUpWizard &&
        this.organizationActive &&
        !this.needsSetup &&
        !this.activeWorkers
    }
  },
  data () {
    return {
      welcomeMessageShown: false,
      // modalComponent is used for displaying Vue modals over legacy Ext components.
      // It receives requests from Ext component via the EventBus.
      // modalProps and modalEvents are used for custom bindings with the modal component.
      modalComponent: null,
      modalProps: null,
      modalEvents: null
    }
  },
  watch: {
    organizationId () {
      this.updateMetadata()
    },
    organizationName () {
      this.updateMetadata()
    },
    displayName () {
      this.updateMetadata()
    },
    email () {
      this.updateMetadata()
    },
    providerId () {
      this.updateMetadata()
    },
    interventionNeeded (value) {
      if (!value) return

      // either user email not verified, org needs acceptance, user fails org auth requirement, no org, or user needs to select one of multiple orgs
      if (!this.emailVerified) {
        // Fatal error dispatched by JwtService
      } else if (!this.selectedOrg) {
        if (this.allOrgs.length < 1) {
          this.initLegacy(() => this.$router.push(`/orgs/sign-up`).catch(() => {}))
        } else {
          this.$nextTick(() => this.$router.push(`/orgs`).catch(() => {}))
        }
      } else if (!this.selectedOrg.orgUserAccepted) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.selectedOrg.id}/accept`).catch(() => {}))
      } else if (!this.selectedOrg.orgUserMeetsAuthRuleRequirements) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.selectedOrg.id}/auth-policy-error`).catch(() => {}))
      } // TODO: else????

      this.clearReloadOnErrorFlag()
    },
    settingsLoaded (newVal, oldVal) {
      this.initLegacy()
      initFcm(this.$store)

      const userRequestedRoute = _.get(this.$router, 'history.pending.matched', []).length > 1
      const userRequestedOutsideOrg = userRequestedRoute && !_.get(this.$router, 'history.pending.params.orgId')

      if (userRequestedOutsideOrg) return

      // all the following router navigations wait until next tick, in case there's already a pending route that needs to be handled first
      if (this.needsSetup) {
        this.$nextTick(() => this.$router.push(`/orgs/${this.organizationId}/sign-up`).catch(() => {}))
      } else if (!this.organizationActive) {
        if (this.permissions.includes('manage_subscription')) {
          this.$nextTick(() => this.$router.push(`/orgs/${this.organizationId}/settings/billing/subscription`).catch(() => {}))
        }
      } else {
        // if user didn't already request a specific route inside the org, then redirect to dashboard
        if (!userRequestedRoute) {
          this.$nextTick(() => this.$router.push({ name: 'dashboard', params: { orgId: this.organizationId } }).catch(() => {}))
        }
      }

      this.clearReloadOnErrorFlag()
    },
    fatalError (newVal) {
      if (newVal) this.$router.push('/error')
    },
    showWelcomeMessage (newVal) {
      if (newVal) {
        const welcomeMessageModal = new Vue({
          ...WelcomeMessage
        })
        welcomeMessageModal.show()
        this.welcomeMessageShown = true
      }
    },
    jwtServiceInitialized: {
      handler (newVal) {
        if (!newVal) return

        // If orgId is set on the url, then we'll set up session for that organization.
        // Because a dynamic import of a component can hold up route resolution, we'll check
        // the pending route if the current route is not set.
        const orgId = _.get(this.$route, 'params.orgId') || _.get(this.$router, 'history.pending.params.orgId')
        this.login(orgId)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['login', 'legacyInitialized']),
    updateMetadata () {
      this.initBugsnag()
    },
    initBugsnag (settings) {
      if (window.Bugsnag) {
        window.Bugsnag.setUser(this.email, this.email, this.displayName)
        window.Bugsnag.addMetadata('user', 'providerId', this.providerId)
        window.Bugsnag.addMetadata('company', {
          id: this.organizationId,
          name: this.organizationName
        })
      }
    },
    clearReloadOnErrorFlag () {
      // See index.html's Bugsnag onError handler for what this local storage item is used for.
      // We'll wait a few seconds in order to help avoid infinite loops.
      if (window.localStorage && window.localStorage.removeItem) {
        setTimeout(() => window.localStorage.removeItem('RELOAD_ON_ERROR'), 5000)
      }
    },
    initLegacy (callback) {
      const settings = _.clone(this.$store.state)

      Object.assign(settings, oConstants)

      window.FcSettings = settings
      window.InitLegacy({
        router: this.$router,
        store: this.$store,
        eventBus,
        shims: {
          _,
          moment,
          importFileSaver
        },
        callback: () => {
          this.legacyInitialized()
          if (callback instanceof Function) callback()
        }
      })
    },
    showConvertPunch (data) {
      // Only one open modal at a time.
      if (this.modalComponent) return

      importConvertPunchModal()
        .then(module => {
          this.modalProps = {}
          this.modalEvents = {
            close: () => {
              this.modalComponent = null
              this.modalProps = null
              this.modalEvents = null
            }
          }
          this.modalComponent = module.default
          this.$nextTick(() => {
            this.$refs.modalComponent.show(data)
          })
        })
    },
    showPunchDetail (options) {
      // Only one open modal at a time.
      if (this.modalComponent) return

      importPunchFormModal()
        .then(module => {
          this.modalProps = {
            itemId: options.punchId
          }
          this.modalEvents = {
            close: () => {
              this.modalComponent = null
              this.modalProps = null
              this.modalEvents = null
            },
            saved: options.onSaved,
            deleted: options.onDeleted,
            'apply-grid-transaction': options.applyGridTransaction
          }
          this.modalComponent = module.default
        })
    },
    showShiftSummary (options) {
      // Only one open modal at a time.
      if (this.modalComponent) return

      // TODO: Use Vue async component's loading/error components
      // TODO: while shift summary modal component chunk is being
      // TODO: downloaded.
      importShiftSummaryModal()
        .then(module => {
          this.modalProps = options
          this.modalEvents = {
            close: () => {
              this.modalComponent = null
              this.modalProps = null
              this.modalEvents = null
            }
          }
          this.modalComponent = module.default
        })
    },
    showTimeOffDateEntriesModal (options) {
      // Only one open modal at a time.
      if (this.modalComponent) return

      importTimeOffDateEntriesModal()
        .then(module => {
          this.modalProps = {
            workerObject: options.workerObject,
            date: options.date
          }
          this.modalEvents = {
            close: () => {
              this.modalComponent = null
              this.modalProps = null
              this.modalEvents = null
            },
            saved: options.onSaved
          }
          this.modalComponent = module.default
        })
    }
  },
  components: {
    Splashscreen
  },
  mounted () {
    eventBus.$on('showConvertPunch', this.showConvertPunch)
    eventBus.$on('showPunchDetail', this.showPunchDetail)
    eventBus.$on('showShiftSummary', this.showShiftSummary)
    eventBus.$on('showTimeOffDateEntriesModal', this.showTimeOffDateEntriesModal)
  }
}
</script>

<style lang="scss">
  /* Import vue-snotify styling */
  @import "~vue-snotify/styles/material";
</style>
<style>
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
  @import '~vue-slider-component/theme/default.css';
  @import '~primevue/resources/primevue.min.css';
  @import '~primevue/resources/themes/bootstrap4-light-blue/theme.css';
  @import '~primeicons/primeicons.css';
  @import '~primeflex/primeflex.css';
</style>
<style lang="scss">
  // Import Main styles for this application
  @import './assets/scss/style';
</style>
<style lang="scss">
@import './assets/scss/vendors/_variables';
// We want to web widget to display directly under the help button,
// not at the bottom of the page.
iframe#webWidget {
  top: $navbar-height;
}
</style>
