<template>
  <hover-icon v-show="!!id" icon="id-card" class="id-icon not-printable">
    <slot>ID: {{ id }}</slot>
  </hover-icon>
</template>
<script>
import HoverIcon from './HoverIcon'

export default {
  name: 'IdIcon',
  components: {
    HoverIcon
  },
  props: {
    id: [Number, String]
  }
}
</script>
