<template>
  <b-card class="panel" body-class="panel-body">
    <ul v-if="!hideHeader" class="panel-header" slot="header">
      <li class="title">{{ title }}</li>
      <slot name="icons"></slot>
    </ul>
    <slot />
  </b-card>
</template>
<script>

export default {
  props: {
    title: String,
    hideHeader: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_bootstrap-variables';

  $border-radius: .25rem;

  $panel-header-bg: #536c79;

  .card {
    border-radius: $border-radius;

    .card-header {
      border-color: darken($panel-header-bg, 5%);
      padding: 0.35rem 1.25rem;
      background-color: $panel-header-bg;
      color: #fff;
      font-weight: bolder;

      border-radius: $border-radius $border-radius 0 0;

      &:first-child {
        border-radius: calc(#{$border-radius} - 1px) calc(#{$border-radius} - 1px) 0 0;
      }

      .panel-header {
        padding: 0; margin: 0; list-style: none;
        display: flex;
        flex-direction: row;

        .title {
          // I'm not sure why text creeps to top without this top margin
          margin-top: 3px;
          margin-right: 1rem;
        }

        ::v-deep .panel-header-icon {
          margin-left: auto;

          ~ .panel-header-icon {
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
</style>
