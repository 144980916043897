<template>
  <div class="column-menu-button">
    <template v-if="primevue">
      <Button
        class="p-button-sm p-button-outlined
        p-button-secondary"
        label="Columns"
        @click="$refs.op.toggle($event)"
      >
        <font-awesome-icon icon="columns" style="margin-right: .5rem;" />
        Columns
      </Button>
      <OverlayPanel ref="op" appendTo="body" :baseZIndex="1060">
        <column-menu
          :allColumns="allColumns"
          :label="label"
          :alternateLabel="alternateLabel"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsSelected"
          @selectedColumnsUpdated="selectedColumnsUpdated"
          @reset-to-defaults="$emit('reset-to-defaults')"
        />
      </OverlayPanel>
    </template>
    <template v-else>
      <b-button :id="`column-menu-${id}`" class="button-minimize-on-sm" :variant="variant" :size="size" @click="toggleColumnMenu">
        <font-awesome-icon icon="columns" />
        Columns
      </b-button>
      <popover :show.sync="showColumnMenu" :target="`column-menu-${id}`" placement="bottom">
        <column-menu
          :allColumns="allColumns"
          :label="label"
          :alternateLabel="alternateLabel"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsSelected"
          @selectedColumnsUpdated="selectedColumnsUpdated"
          @reset-to-defaults="$emit('reset-to-defaults')"
        />
      </popover>
    </template>
  </div>
</template>
<script>
import ColumnMenu from './ColumnMenu'
import Popover from '@/components/Popover'
import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'

export default {
  components: {
    Button,
    ColumnMenu,
    OverlayPanel,
    Popover
  },
  props: [
    'allColumns',
    'columnLabelKey',
    'columnIsSelected',
    'label',
    'alternateLabel',
    'variant',
    'size',
    'primevue'
  ],
  data () {
    return {
      id: null,
      showColumnMenu: false
    }
  },
  methods: {
    toggleColumnMenu () {
      this.showColumnMenu = !this.showColumnMenu
    },
    selectedColumnsUpdated (selectedColumns) {
      this.$emit('selectedColumnsUpdated', selectedColumns)
    }
  },
  beforeMount () {
    this.id = this._uid
  }
}
</script>
