
export default {
  name: 'sudo',
  path: 'sudo',
  props: true,
  component: () => import('@/components/TabPanel'),
  children: [
    {
      name: 'sudo-org-users',
      title: 'Org Users',
      path: 'org-users/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "sudo" */ './SudoOrgUsers'),
      meta: {
        label: 'Org Users',
        icon: 'user-magnifying-glass',
        requireActive: false,
        nav: true
      }
    },
    {
      path: '',
      redirect: { name: 'sudo-org-users' }
    }
  ],
  meta: {
    label: 'Sudo',
    link: 'sudo',
    icon: 'crown',
    requireActive: false,
    nav: true,
    breadcrumbLeaf: true,
    require: (state, getters) => {
      return getters['userProfile/isSudoAccessToAllOrgs']
    },
  }
}
