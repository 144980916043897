import ActiveRoute from './ActiveRoute'

export default {
  data () {
    return {
      routeUpdateNext: null,
      routeUpdateTo: null,
      routeLeaveNext: null
    }
  },
  mixins: [
    ActiveRoute
  ],
  beforeRouteUpdate (to, from, next) {
    this.routeUpdateTo = to
    this.routeUpdateNext = next
  },
  beforeRouteLeave (to, from, next) {
    this.routeLeaveNext = next
  },
  computed: {
    // The way we compute an entire routeHooks object here,
    // seems that it can cause consumer watchers to fire on
    // activeRoute changing even though only routeLeaveNext
    // changes. See AbstractReport.vue.
    // TODO: Is there a better way to do this?
    routeHooks () {
      return {
        activeRoute: this.activeRoute,
        routeUpdateTo: this.routeUpdateTo,
        routeUpdateNext: this.routeUpdateNext,
        routeLeaveNext: this.routeLeaveNext
      }
    }
  }
}
