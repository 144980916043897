<template>
  <panel title="User Usage">
    <chart-doughnut
      :options="chartOptions"
      :chartData="chartData"
      :width="250"
      :height="150"
    />
    <div class="axis-labels">
      <span>0</span>
      <span>{{ maxUsers }}</span>
    </div>
    <b-button variant="primary" @click="addUser">
      <font-awesome-icon icon="user-plus" class="icon" /> Add User
    </b-button>
    <b-alert class="upgrade-alert" variant="danger" :show="showUpgradeAlert" dismissible @dismissed="showUpgradeAlert = false">
      <font-awesome-icon icon="triangle-exclamation" class="icon" /> See your primary administrator to upgrade subscription
    </b-alert>
  </panel>
</template>
<script>
import Panel from '@/components/Panel'
import ChartDoughnut from '@/components/ChartDoughnut'
import { mapGetters, mapState } from 'vuex'

// sample: https://jsfiddle.net/oqspe5jv/1/

const colors = {
  green: {
    backgroundColor: '#2ecc71',
    borderColor: '#27ae60'
  },
  yellow: {
    backgroundColor: '#f1c40f',
    borderColor: '#f39c12'
  },
  red: {
    backgroundColor: '#e74c3c',
    borderColor: '#c0392b'
  },
  gray: {
    backgroundColor: '#ecf0f1',
    borderColor: '#bdc3c7'
  }
}

function baseChartData () {
  return {
    labels: ['Active users', 'Unused user seats'],
    datasets: [{
      borderWidth: 1
    }]
  }
}

export default {
  components: {
    Panel,
    ChartDoughnut
  },
  computed: {
    ...mapState(['maxUsers', 'permissions']),
    ...mapGetters(['billableUserCount']),
    chartData () {
      const chartData = baseChartData()
      const used = Math.min(this.billableUserCount, this.maxUsers)
      const unused = this.maxUsers - used
      const usedColor = unused === 0
        ? colors.red
        : (used >= unused * 9 ? colors.yellow : colors.green)

      Object.assign(chartData.datasets[0], {
        data: [used, unused],
        backgroundColor: [
          usedColor.backgroundColor,
          colors.gray.backgroundColor
        ],
        borderColor: [
          usedColor.borderColor,
          colors.gray.borderColor
        ]
      })

      return chartData
    }
  },
  data () {
    return {
      chartOptions: {
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: '# of Employees'
        },
        responsive: true
      },
      showUpgradeAlert: false
    }
  },
  methods: {
    addUser () {
      if (this.billableUserCount < this.maxUsers) {
        this.$router.push({ name: 'employee-list', params: { view: 'new' } })
      } else if (this.permissions.includes('manage_subscription')) {
        this.$router.push({ name: 'billing-subscription' })
      } else {
        this.showUpgradeAlert = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  ::v-deep .panel-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .axis-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > span {
      margin: 0 .25rem;
      font-size: .75rem;
      color: #777;
      font-weight: bolder;
    }
  }

  .btn {
    border-radius: .25rem;
  }

  .upgrade-alert {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .icon {
    margin-right: 3px;
  }

</style>
