<template>
  <b-navbar-nav class="user-header-dropdown">
    <b-nav-item-dropdown right no-caret>
      <template slot="button-content">
        <b-avatar variant="secondary" :src="avatarUrl" :text="avatarText" size="35px" referrerpolicy="no-referrer"></b-avatar>
      </template>
      <b-dropdown-header tag="div" class="text-center">
        <strong>{{ userName }}</strong>
        <br/>
        {{ emailAddress }}
      </b-dropdown-header>
      <b-dropdown-item :href="`${loginServer}/manage`" target="_blank"><font-awesome-icon icon="id-card" class="icon" /> Profile</b-dropdown-item>
      <b-dropdown-item :href="`${loginServer}/change-password`" target="_blank"><font-awesome-icon icon="key" class="icon" /> Change Password</b-dropdown-item>
      <b-dropdown-item v-if="isSudoAccessToAllOrgs" @click="goToSudo"><font-awesome-icon icon="crown" class="icon" /> Sudo</b-dropdown-item>
      <b-dropdown-item v-on:click="logout()"><font-awesome-icon icon="lock" class="icon" /> Logout</b-dropdown-item>

    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import constants from '@/constants'

const loginServer = constants().loginServer

export default {
  name: 'UserHeaderDropdown',
  data () {
    return {
      loginServer
    }
  },
  computed: {
    ...mapGetters('userProfile', ['isSudoAccessToAllOrgs']),
    ...mapState('userProfile', ['userName', 'emailAddress']),
    ...mapState('jwt', ['idaasClaims']),
    avatarUrl () {
      return this.idaasClaims?.picture
    },
    avatarText () {
      // https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
      return this.avatarUrl ? null : this.idaasClaims?.name?.match(/(\b\S)?/g)?.join('')?.match(/(^\S|\S$)?/g)?.join('')?.toUpperCase()
    }
  },
  methods: {
    ...mapActions(['logout']),
    goToSudo () {
      this.$router.push('/sudo')
    }
  }
}
</script>
<style lang="scss" scoped>

.icon {
  margin-right: 5px;
}
.fa-circle-user {
  /* match size of original avatar image included in core ui */
  font-size: 35px;
}
</style>
