import _ from 'lodash'

export default {
  data () {
    return {
      columnPrefsInternal: [],
      // The following properties are defaulted to support Sync Fusion grid columns,
      // like how it's used by AbstractReport.
      // It can be overridden, such as for ag-grid.
      columnFieldKey: 'field',
      columnLabelKey: 'headerText',
      columnIsVisible: columnDef => columnDef ? (columnDef.visible !== false) : false,
      columnVisibleEntry: (visible) => ({ visible: visible }),
      // Typically, allColumns is passed as a prop into the component that mixes in this class.
      // This pattern works when the component which defines the columns extends a base component which
      // mixes in this class. However, when the component both mixes in this class and defines the columns,
      // then using a prop doesn't work. So, we'll allow such a component to specify its own columns property,
      // and then also specify the key for it by overriding the allColumnsKey property.
      allColumnsKey: 'allColumns'
    }
  },
  props: {
    allColumns: Array
  },
  computed: {
    allAppliedColumns () {
      return this[this.allColumnsKey]
    },
    availableColumns () {
      // TODO: Should we keep available as an ergonomic way to control columns based on state,
      // TODO: or should we make parent component provide the available columns as allColumns?
      return this.allAppliedColumns.filter(col => col.available !== false)
    },
    // TODO: Rename the all* computed properties below to available*.
    allColumnsByField () {
      return Object.fromEntries(this.availableColumns.map(col => [col.columnDef[this.columnFieldKey], col]))
    },
    allColumnFields () {
      return Object.keys(this.allColumnsByField)
    },
    defaultColumnPrefs () {
      return this.availableColumns
        // TODO: Why do we need hideByDefault? Just use columnDef.visible.
        // TODO: I think we originally had it due to the previously obtuse design of this mixin.
        .filter(col => col.hideByDefault !== true)
        .filter(col => this.columnIsVisible(col.columnDef))
        .map(col => col.columnDef[this.columnFieldKey])
    },
    columnPrefs () {
      return this.columnPrefsInternal.length > 0 ? this.columnPrefsInternal : this.defaultColumnPrefs
    },
    areColumnPrefsDefault () {
      return _.isEqual(this.columnPrefs, this.defaultColumnPrefs.map(fieldName => ({ [this.columnFieldKey]: fieldName })))
    },
    // Originally, column prefs was just an ordered list of field names to make visible.
    // Later on, we added width as a preference, so we needed to make column preference an object.
    // We'll still maintain backwards compatibility, so each preference item can be either a string
    // field name, or an object containing field name, width, and potentially any other preferences
    // we decide to add in the future.
    columnPrefFields () {
      return this.columnPrefs.map(col => _.isPlainObject(col) ? col[this.columnFieldKey] : col)
    },
    columnPrefObjects () {
      return this.columnPrefs.map(col => _.isPlainObject(col)
        ? col
        : {
          [this.columnFieldKey]: col,
          width: _.get(this.allColumnsByField[col], 'columnDef.width')
        }
      )
    },
    columnPrefObjectsByField () {
      return Object.fromEntries(this.columnPrefObjects.map(col => [col[this.columnFieldKey], col]))
    },
    allColumnDefsInPreferredOrder () {
      // The naive approach would be to just construct a list of with all visible
      // columns first in their preferred order. But it will make the column menu
      // look very different than the default order, which seems disorienting.
      // So we'll try to keep the column order as close to original as possible.

      // Make copy of all column definitions, with visibility and other preferences set.
      const allColumnDefs = this.availableColumns.map(col =>
        Object.assign(
          {},
          col.columnDef,
          this.columnPrefObjectsByField[col.columnDef[this.columnFieldKey]],
          this.columnVisibleEntry(this.columnPrefFields.includes(col.columnDef[this.columnFieldKey]))
        )
      )

      let prevColumnPrefPos = -1
      this.columnPrefObjects.forEach(columnPref => {
        const field = columnPref[this.columnFieldKey]
        const pos = allColumnDefs.findIndex(columnDef => columnDef[this.columnFieldKey] === field)

        if (pos < 0) return

        // If columnPref is not already first visible column after previous one, then move it
        // to immediately after previous one.
        const firstVisiblePos = _.findIndex(allColumnDefs, col => this.columnIsVisible(col), prevColumnPrefPos + 1)
        // Somehow the following column prefs have been seen on a user in local storage:
        // [{"field":"orgUnitName"},{"field":"departmentName"},{"field":"labelNames"},{"field":"payClassName"},{"field":"lastPunch"},{"field":"pin"},{"field":"neverHadFace"},{"field":"active"},{"field":"avatar"},{"field":"lastName"},{"field":"firstName"},"avatar"]
        // Note the duplicate avatar field.
        // It causes firstVisiblePos to be -1, so we check for that condition.
        // TODO: Need to figure out how the duplicate field occurred in the first place.
        if (firstVisiblePos < 0) {
          prevColumnPrefPos += 1
        } else if (allColumnDefs[firstVisiblePos][this.columnFieldKey] !== field) {
          this.moveItemInArray(allColumnDefs, pos, prevColumnPrefPos + 1)
          prevColumnPrefPos += 1
        } else {
          prevColumnPrefPos = firstVisiblePos
        }
      })

      return allColumnDefs
    },
    allColumnDefsInPreferredOrderForMenu () {
      return this.allColumnDefsInPreferredOrder
        .filter(columnDef => {
          const col = this.allColumnsByField[columnDef[this.columnFieldKey]]
          return col.showInColumnMenu !== false
        })
        .map(columnDef => {
          const col = this.allColumnsByField[columnDef[this.columnFieldKey]]
          return col.columnMenuHeaderName
            ? Object.assign({}, columnDef, { [this.columnLabelKey]: col.columnMenuHeaderName })
            : columnDef
        })
    }
  },
  methods: {
    moveItemInArray (list, fromIndex, toIndex) {
      const column = list.splice(fromIndex, 1)[0]
      list.splice(toIndex, 0, column)
    },
    columnPrefsUpdated(columnPrefs) {
      // dedupe
      if (columnPrefs && !_.isEqual(columnPrefs, this.columnPrefsInternal)) {
        this.columnPrefsInternal = columnPrefs
      }
    },
    columnWidthUpdated (field, width) {
      this.columnPrefsUpdated(this.columnPrefObjects.map(col =>
        col[this.columnFieldKey] === field
          ? Object.assign({}, col, { width: width })
          : col
      ))
    },
    resetColumnsToDefaults () {
      this.columnPrefsUpdated(this.defaultColumnPrefs)
    }
  }
}
