<template>
  <b-sidebar right no-header v-model="forwardVisible"
    bg-variant="sidebar"
    text-variant="sidebar"
    :sidebar-class="$style['form-drawer-sidebar']"
    :no-close-on-route-change="noCloseOnRouteChange"
    @change="forwardVisible = $event"
  >
    <div :class="$style['form-drawer-content']">
      <slot></slot>

      <hr/>

      <slot name="footer-above-buttons"></slot>

      <slot name="footer-buttons">
        <b-button
          @click="save"
          :disabled="saveDisabled"
          variant="primary"
          class="action save"
          size="sm"
        >
          <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
          {{ saveText }}
        </b-button>
        <b-button
          @click="cancel"
          :disabled="cancelDisabled"
          variant="secondary"
          class="action cancel"
          size="sm"
        >
          <font-awesome-icon icon="circle-xmark" />
          {{ cancelText }}
        </b-button>
      </slot>

      <slot name="footer-below-buttons"></slot>

      <mask-spinner v-show="mask" />
    </div>
  </b-sidebar>
</template>

<script>
import MaskSpinner from './MaskSpinner'

export default {
  components: {
    MaskSpinner
  },
  data () {
    return {
      height: undefined
    }
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    saveText: {
      type: String,
      default: 'Save'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    noCloseOnRouteChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    forwardVisible: {
			get () {
				return this.visible
			},
			set (v) {
				this.$emit('change', v)
			}
    }
  },
  methods: {
    save () {
      this.$emit('saveForm')
    },
    cancel () {
      this.$emit('cancelForm')
    }
  }
}
</script>
<style lang="scss" module>
  @import '../assets/scss/_bootstrap-variables';

  // TODO: need to define in shared resource
  $header-height: 55px;
  $footer-height: 50px;

  .form-drawer-sidebar {
    // top should be under header
    top: $header-height;

    // and bottom should be above footer
    height: calc(100vh - #{$header-height} - #{$footer-height});

    border-left: 1px solid $gray-300;
    background-color: #eee;

    .form-drawer-content {
      padding: 25px;
      padding-top: 25px;
      opacity: 1.0;

      // Specifing global for action selector enables styling for scope overrides
      :global(.action) {
        margin-top: 25px;
        &:global(.cancel) {
          margin-left: 10px;
        }
      }
    }
  }
</style>
