import ActiveRoute from './ActiveRoute'
import { hasAccessToRoute } from '@/router/RouterAccessGuard'
import _ from 'lodash'

// This mixin is typically used for components rendered in a router-view.
// It can be used to access the configured route options for displayed child
// links, e.g., table of contents or tab panel.
export default {
  mixins: [ActiveRoute],
  data () {
    return {
      includeInKeepAlive: []
    }
  },
  computed: {
    label () {
      return this.activeRoute ? this.activeRoute.meta.label : null
    },
    icon () {
      return this.activeRoute ? this.activeRoute.meta.icon : null
    },
    parent () {
      if (_.get(this.activeRoute, 'matched.length') > 2) {
        // parent is 2nd to last element
        return _.last(_.initial(this.activeRoute.matched))
      } else {
        // do not consider first element as a parent in navigation
        return null
      }
    },
    children () {
      // this.$route.matched contains list of RouteRecords, but it doesn't contain
      // the current route's children. So we need to look that up in a separate
      // object.
      const configuredRoutes = this.$router.options.routes
      let routeCursor = { children: configuredRoutes }

      if (this.activeRoute) {
        for (const matchedRoute of this.activeRoute.matched) {
          routeCursor = (routeCursor.children || []).find(
            route => route.name === matchedRoute.name)
          if (!routeCursor || matchedRoute.instances.default === this) {
            // unexpected
            break
          }
        }
      }

      return ((routeCursor && routeCursor.children) || [])
        .filter(child => child.meta && child.meta.nav)
        .filter(child => hasAccessToRoute(child, this.$store))
    }
  },
  watch: {
    activeRoute () {
      this.checkKeepAlive()
    }
  },
  methods: {
    checkKeepAlive () {
      // We only want to cache certain components, such as the dashboard.
      // We configure keepAlive in route configuration for each components.
      // Note that the component must have a name in order to keep alive.
      // Note that due to Vue's use of component name for enabling cache,
      // if any instance of that component is cached, then all instances will be,
      // such as RouterPassThrough.
      // Note that the component that mixes in this class must declare a
      // <keep-alive> directive, and bind include to includeInKeepAlive.

      const routeMatchedIndex = (this.$route.matched || [])
        .findIndex(route => route.instances.default === this)
      if (routeMatchedIndex > -1 && this.$route.matched.length > routeMatchedIndex + 1) {
        const childRouteInView = this.$route.matched[routeMatchedIndex + 1]
        if (childRouteInView.meta.keepAlive) {
          const childComponent = childRouteInView.components.default
          if (!this.includeInKeepAlive.includes(childComponent.name)) {
            this.includeInKeepAlive.push(childComponent.name)
            // const selfComponent = this.$route.matched[routeMatchedIndex].components.default
            // console.debug(`Including ${childComponent.name} in keep alive for ${selfComponent.name}`)
          }
        }
      }
    }
  },
  mounted () {
    this.checkKeepAlive()
  }
}
