// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import App from './App'
import EventBus from './EventBus'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'
import { guardRouteAccess } from './router/RouterAccessGuard'
import { initActivityMonitor } from './services/ActivityMonitorService'
import { initSizeMonitor } from './services/SizeMonitorService'
import { registerJwtServiceWithStore } from './services/JwtService'
import { registerUserProfileWithStore } from './services/UserProfileService'
import { registerOrgServiceWithStore } from './services/OrgService'
import { registerFormatPreferencesWithStore } from './services/FormatPreferencesService'
import { registerDashboardNotificationsWithStore } from './services/NotificationService'
import './directives/ClickOutside'
import './directives/Mask'
import './filters/Inflect'
import './filters/Number'
import './filters/String'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import Multiselect from 'vue-multiselect'
import VueImgFallback from 'v-img-fallback'
import i18n from './i18n'
import 'moment'
import momentTimezone from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import { extendMoment } from 'moment-range'
import FormGroup from './components/form/FormGroup.vue'
import FormSummary from './components/form/FormSummary.vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import constants from '@/constants'
import PrimeVue from 'primevue/config'

// fonts
import "@fontsource/open-sans/latin-400.css"

// Explictly import polyfills that vue cli 3 doesn't automatically polyfill,
// since it uses the older core-js 2. This should be fixed in vue cli 4:
// https://github.com/vuejs/vue-cli/issues/3834#issuecomment-484349452
// import 'core-js/stable/array/flat-map'

if (window.Bugsnag) {
  // Now that Vue is loaded, we can install Bugsnag's Vue plugin.
  const bugsnagPluginVue = new BugsnagPluginVue(Vue)
  bugsnagPluginVue.load(window.Bugsnag._client)
}

const development = constants().development

// moment-duration-format automatically installs on default moment instance.
// we need to manually install it on moment-timezone.
momentDurationFormatSetup(momentTimezone)
// Set defaults.
Object.assign(momentTimezone.duration.fn.format.defaults, {
  trim: false,
  round: true
})
extendMoment(momentTimezone)

Vue.use(BootstrapVue)
Vue.use(VueImgFallback)

Vue.use(Vuelidate)
Vue.use(vuelidateErrorExtractor, {
  // global reusable validation error message
  messages: {
    required: '{attribute} is a required field.',
    email: '{attribute} must be a valid email address.',
    integer: '{attribute} must be a integer value.',
    numeric: '{attribute} must be a numeric value.',
    decimal: '{attribute} must be a decimal value.',
    phoneE164: '{attribute} must be a valid phone number.',
    between: '{attribute} must be between {min} and {max}.',
    minValue: '{attribute} must be at least {min}.',
    maxValue: '{attribute} must be no more than {max}.',
    minLength: '{attribute} must be at least {min} characters.',
    maxLength: '{attribute} must be no more than {max} characters.',
    dateTime: '{attribute} must be a valid datetime.',
    timeMinValue: '{attribute} must be at least {min}.',
    timeMaxValue: '{attribute} must be at least {max}.',
    time: '{attribute} must be a valid time.',
    unique: '{attribute} must be unique.',
    maxDecimalAccuracy: '{attribute} must not have more than {max} decimal places.'
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
faLibrary.add(fab, far, fas)

Vue.component('multiselect', Multiselect)
Vue.component('FormGroup', FormGroup)
Vue.component('FormSummary', FormSummary)
Vue.component('formWrapper', templates.FormWrapper)

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false,
    timeout: 3000
  }
})
Vue.use(PrimeVue)

sync(store, router)
guardRouteAccess(router, store)
initActivityMonitor(store)
initSizeMonitor(store)
registerJwtServiceWithStore(store)
registerUserProfileWithStore(store)
registerOrgServiceWithStore(store)
registerFormatPreferencesWithStore(store)
registerDashboardNotificationsWithStore(store)

Vue.prototype.$eventBus = EventBus

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  i18n,

  components: {
    App
  }
})

// TODO: Inject the following development code from a separate module using webpack.
if (development) {
  // Add development tooling to window object.
  // diff was provided to webpack in vue.config.js.
  import('deep-object-diff').then(module => {
    window.diff = module
  })
  // Add ability to break in debugger if a particular property changes.
  // It became useful to debug SyncFusion.
  // https://stackoverflow.com/a/11658693/1237919
  console.watch = function(oObj, sProp) {
    var sPrivateProp = "$_"+sProp+"_$" // to minimize the name clash risk
    oObj[sPrivateProp] = oObj[sProp]

    // overwrite with accessor
    Object.defineProperty(oObj, sProp, {
        get: function () {
            return oObj[sPrivateProp]
        },

        set: function (value) {
            //console.log("setting " + sProp + " to " + value);
            debugger // sets breakpoint
            oObj[sPrivateProp] = value
        }
    })
  }
}
